/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Header.HeaderMenu';

export default defineMessages({
  invest: {
    id: `${scope}.invest.message`,
    defaultMessage: 'All projects',
  },
  raise: {
    id: `${scope}.raise.message`,
    defaultMessage: 'Candidate a project',
  },
  investGuide: {
    id: `${scope}.invest_guide.message`,
    defaultMessage: 'Invest Guide',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
});
