/*
 *
 * Header actions
 *
 */

import { FEParsedError } from 'types/Error';
import {
  LOAD_ADDITIONAL_HEADER,
  LOAD_ADDITIONAL_HEADER_SUCCESS,
  LOAD_ADDITIONAL_HEADER_ERROR,
} from './constants';

interface LoadAdditionalHeaderAction {
  type: typeof LOAD_ADDITIONAL_HEADER;
}

interface LoadAdditionalHeaderSuccessAction {
  type: typeof LOAD_ADDITIONAL_HEADER_SUCCESS;
  headerLink: any;
}

interface LoadAdditionalHeaderErrorAction {
  type: typeof LOAD_ADDITIONAL_HEADER_ERROR;
  error: FEParsedError | boolean;
}

export type HeaderActionTypes =
  | LoadAdditionalHeaderAction
  | LoadAdditionalHeaderSuccessAction
  | LoadAdditionalHeaderErrorAction;

export function loadAdditionalHeader(): LoadAdditionalHeaderAction {
  return {
    type: LOAD_ADDITIONAL_HEADER,
  };
}

export function loadAdditionalHeaderSuccess(
  headerLink: any,
): LoadAdditionalHeaderSuccessAction {
  return {
    type: LOAD_ADDITIONAL_HEADER_SUCCESS,
    headerLink,
  };
}

export function loadAdditionalHeaderError(
  error: FEParsedError | boolean,
): LoadAdditionalHeaderErrorAction {
  return {
    type: LOAD_ADDITIONAL_HEADER_ERROR,
    error,
  };
}
