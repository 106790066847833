import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  MAX_WIDTH_PAGE,
  HEADER_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  CONSOLE_HEADER_HEIGHT,
} from 'utils/constants';

type Props = {
  isInSec?: boolean;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  header: props => ({
    width: '100%',
    minHeight: props.isInSec ? CONSOLE_HEADER_HEIGHT : HEADER_MOBILE_HEIGHT,
    [theme.breakpoints.up('md')]: {
      minHeight: props.isInSec ? CONSOLE_HEADER_HEIGHT : HEADER_HEIGHT,
    },
  }),
  headerContainer: {
    backgroundColor:
      process.env.SERVER_BE === 'staging'
        ? theme.palette.common.bgHeaderDev
        : theme.palette?.background?.default || theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    boxShadow: 'none',
    zIndex: 5,
  },
  headerItemsContainer: props => ({
    width: '100%',
    height: props.isInSec ? CONSOLE_HEADER_HEIGHT : HEADER_MOBILE_HEIGHT,
    maxWidth: props.isInSec ? '100%' : MAX_WIDTH_PAGE,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      height: props.isInSec ? CONSOLE_HEADER_HEIGHT : HEADER_HEIGHT,
    },
  }),
  menuIcon: {
    width: 30,
    height: 30,
    color: theme.palette.common.darkGray,
  },
}));
