import User from 'utils/User';
import {
  CAMPAIGNS_ROUTE,
  APPLY_PAGE_ROUTE,
  INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  NEWS_PAGE_ROUTE,
  CONSOLE_PREFIX,
  CONSOLE_HOME_PAGE_ROUTE,
  SIM_PAGE_ROUTE,
  MONITORING_PAGE_ROUTE,
  ME_PORTFOLIO_ROUTE,
  ME_WATCHLIST_ROUTE,
  ME_CAMPAIGNS_ROUTE,
  ME_COMPANY_MONITORING_ROUTE,
  ME_PROFILE_ROUTE,
  ME_EARLY_STAGE_ADVISORY_ROUTE,
} from 'utils/constants';

export type MenuLink = {
  translationId: string;
  link: string;
  icon?: string;
  hidden?: boolean | (() => boolean);
  badge?: boolean | (() => boolean);
};

// Estrazione delle proprietà da MENU_LINKS in costanti separate
export const INVEST: MenuLink = {
  translationId: 'invest',
  link: CAMPAIGNS_ROUTE,
};

export const PORTFOLIO: MenuLink = {
  translationId: 'portfolio',
  link: ME_PORTFOLIO_ROUTE,
  hidden: () => !User.isLogged(),
};

export const INVEST_GUIDE: MenuLink = {
  translationId: 'investGuide',
  link: INDEX_KNOWLEDGE_INVESTOR_ROUTE,
};

export const RAISE: MenuLink = {
  translationId: 'raise',
  link: APPLY_PAGE_ROUTE,
};

export const CAMPAIGNS: MenuLink = {
  translationId: 'campaigns',
  link: ME_CAMPAIGNS_ROUTE,
  hidden: () => !User.getUserProp('campaignOwner'),
};

export const RAISE_GUIDE: MenuLink = {
  translationId: 'raiseGuide',
  link: INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  hidden: () => !User.getUserProp('campaignOwner'),
};

export const NEWS: MenuLink = {
  translationId: 'news',
  link: NEWS_PAGE_ROUTE,
};

export const PROFILE: MenuLink = {
  icon: 'profile',
  translationId: 'profile',
  link: ME_PROFILE_ROUTE,
};

export const WATCHLIST: MenuLink = {
  icon: 'watchlist',
  translationId: 'watchlist',
  link: ME_WATCHLIST_ROUTE,
};

export const SIM: MenuLink = {
  icon: 'sim',
  translationId: 'sim',
  link: SIM_PAGE_ROUTE,
  hidden: () => {
    const permissions = User.getUserPermissions();
    return (
      !permissions ||
      (permissions &&
        !!Object.keys(permissions).length &&
        !Object.keys(permissions).includes('sim') &&
        !Object.keys(permissions).includes('*'))
    );
  },
};

export const MONITORING: MenuLink = {
  icon: 'monitoring',
  translationId: 'monitoring',
  link: MONITORING_PAGE_ROUTE,
  hidden: () => !User.getUserProp('companyMonitoringProfileOwner'),
};

export const COMPANY_REPORTS: MenuLink = {
  // icon: 'companyReports',
  translationId: 'companyReports',
  link: ME_COMPANY_MONITORING_ROUTE,
  hidden: () => !User.getUserProp('companyMonitoringOwner'),
};

export const EARLY_STAGE_ADVISORY: MenuLink = {
  translationId: 'earlyStageAdvisory',
  link: ME_EARLY_STAGE_ADVISORY_ROUTE,
  hidden: () => !User.isLogged() || process.env.NODE_ENV === 'production',
};

export const CONSOLE: MenuLink = {
  icon: 'console',
  translationId: 'console',
  link: CONSOLE_PREFIX + CONSOLE_HOME_PAGE_ROUTE,
  hidden: () => {
    const permissions = User.getUserPermissions();
    return (
      !permissions ||
      (permissions &&
        Object.keys(permissions).length === 1 &&
        Object.keys(permissions).includes('sim'))
    );
  },
};

// Funzione helper per processare i link
const processLinks = (links: MenuLink[]) =>
  links.map(link => {
    const menuLink = { ...link };
    // Esegui la funzione hidden se presente
    if (
      menuLink.hidden !== undefined &&
      typeof menuLink.hidden === 'function'
    ) {
      menuLink.hidden = menuLink.hidden();
    }
    if (menuLink.badge !== undefined && typeof menuLink.badge === 'function') {
      menuLink.badge = menuLink.badge();
    }
    return menuLink;
  });

// Struttura del menu con possibilità di filtrare per sezione
export const getMenuLinks = (section?: string): MenuLink[] => {
  switch (section) {
    case 'invest':
      return processLinks([INVEST, PORTFOLIO, INVEST_GUIDE]);
    case 'raise':
      return processLinks([
        RAISE,
        CAMPAIGNS,
        EARLY_STAGE_ADVISORY,
        COMPANY_REPORTS,
        RAISE_GUIDE,
      ]);
    case 'public':
      return processLinks([NEWS]);
    case 'account':
      return processLinks([PROFILE, WATCHLIST, SIM, MONITORING, CONSOLE]);
    case 'desktop':
      return processLinks([INVEST, RAISE, INVEST_GUIDE, NEWS]);
    default:
      return [];
  }
};
