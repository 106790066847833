import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
  pageContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& a:not([class^="MuiButton"])': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
