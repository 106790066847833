/**
 *
 * UserMenu
 *
 */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  Divider,
  Grid,
  ListItemIcon,
  useMediaQuery,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import Language from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';

import { Button, Chip, Typography } from '@mamacrowd/ui-kit';

import { appLocales, DEFAULT_LOCALE } from 'i18n';

import LogoutButton from 'containers/LogoutButton';
import FormattedMessage from 'components/FormattedMessage';
import { changeLocale } from 'containers/LanguageProvider/actions';

import User from 'utils/User';
import LocalStorage from 'utils/localStorage';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { trackChangeLanguage, trackEvent } from 'utils/trackers';
import { LOGIN_ROUTE, REGISTER_ROUTE } from 'utils/constants';

import WatchlistIcon from 'images/UserDropdown/watchlist_icon.svg';
import ConsoleIcon from 'images/UserDropdown/console_icon.svg';
import AccountIcon from 'images/UserDropdown/account_icon.svg';
import SimIcon from 'images/UserDropdown/sim_icon.svg';
import UserLogged from 'images/UserLogged/user-logged-true.svg';
// import Portfolio from 'images/UserDropdown/portfolio.svg';
// import Projects from 'images/UserDropdown/projects.svg';

import { useStyle } from './UserMenu.style';
import { getMenuLinks, MenuLink } from '../HeaderLinks';
import messages from './messages';

interface UserMenuProps {
  additionalLink: {
    title: string;
    url: string;
    active: boolean;
  };
  isPowerUser: boolean;
  onLocaleToggle: (language: string) => void;
}

function UserMenu({
  additionalLink,
  isPowerUser = false,
  onLocaleToggle,
}: UserMenuProps) {
  const theme = useTheme();
  const classes = useStyle();
  const navigate = useHistory();
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));
  const isUserLogged = User.isLogged();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openSections, setOpenSections] = useState({
    invest: true,
    raise: true,
    account: isOverMD ? true : false,
  });

  const getIcon = (icon: string) => {
    switch (icon) {
      // case 'Portfolio':
      //   return <img src={Portfolio} alt="Portfolio" width="20" height="20" />;
      // case 'campaigns':
      //   return <img src={Projects} alt="Campaigns" width="20" height="20" />;
      case 'watchlist':
        return <img src={WatchlistIcon} alt="Watchlist" width="20" height="20" />; // eslint-disable-line prettier/prettier
      case 'profile':
        return <img src={AccountIcon} alt="Profile" width="20" height="20" />;
      case 'console':
        return <img src={ConsoleIcon} alt="Console" width="20" height="20" />;
      case 'sim':
        return <img src={SimIcon} alt="Sim" width="20" height="20" />;
      case 'companyReports':
        return <ArticleOutlined color="action" style={{ width: 20, height: 20, color: '#000000' }} />; // eslint-disable-line prettier/prettier
      case 'monitoring':
        return <ComputerIcon color="action" style={{ width: 20, height: 20, color: '#000000' }} />; // eslint-disable-line prettier/prettier
      default:
        return null;
    }
  };

  const handleExtraLinkClick = () => {
    trackEvent('header_extra_link_click', additionalLink.url, 'Nav');
    navigate.push(additionalLink.url);
  };

  const toggleSection = section => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleMenuItemClick = selectedLanguage => {
    setAnchorEl(null);
    trackChangeLanguage(selectedLanguage, true);
    onLocaleToggle(selectedLanguage);
    LocalStorage.setItem('user-language', selectedLanguage);
  };

  const renderMenuSection = (links: MenuLink[], sectionTitle?: string) => (
    <>
      {sectionTitle && (sectionTitle !== 'account' || !isOverMD) && (
        <ListItemButton
          onClick={() => toggleSection(sectionTitle)}
          disableRipple
          disableTouchRipple
        >
          {sectionTitle === 'account' && isUserLogged && (
            <ListItemIcon sx={{ minWidth: 43 }}>
              <img src={UserLogged} alt="logged-user-icon" />
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{
              className: classes.listItemText,
              fontWeight: 700,
            }}
          >
            {sectionTitle === 'account' && isUserLogged ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="inherit" color="inherit">
                  {User.getUserProp('firstName')}
                </Typography>
                {isPowerUser && (
                  <Chip
                    variant="outlined"
                    color="earlyBird"
                    size="small"
                    className={classes.powerUserChip}
                    label={
                      <FormattedMessage
                        messages={messages}
                        messageId="powerUser"
                      />
                    }
                  />
                )}
              </Stack>
            ) : (
              <FormattedMessage
                messages={messages}
                messageId={`${sectionTitle}Section`}
              />
            )}
          </ListItemText>
          <ExpandMoreIcon
            className={`${classes.expandIcon} ${
              openSections[sectionTitle] ? classes.expandIconOpen : ''
            }`}
          />
        </ListItemButton>
      )}
      <Collapse in={sectionTitle ? openSections[sectionTitle] : true}>
        <List dense={isOverMD} disablePadding>
          {links
            .filter(link => !link.hidden)
            .map((link, index) => (
              <ListItemButton key={index} href={link.link}>
                {link.icon && (
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    {getIcon(link.icon)}
                  </ListItemIcon>
                )}
                <ListItemText
                  primaryTypographyProps={{ className: classes.listItemText }}
                >
                  <FormattedMessage
                    messages={messages}
                    messageId={link.translationId}
                  />
                  {link.translationId === 'earlyStageAdvisory' && (
                    <Chip
                      variant="filled"
                      color="warning"
                      size="small"
                      sx={{
                        ml: 1,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                      label={
                        <FormattedMessage
                          messages={messages}
                          messageId="beta"
                        />
                      }
                    />
                  )}
                </ListItemText>
              </ListItemButton>
            ))}
        </List>
      </Collapse>
    </>
  );

  return (
    <List dense={isOverMD}>
      {renderMenuSection(getMenuLinks('invest') || [], 'invest')}
      <Divider variant="middle" sx={{ mx: 2, my: 1 }} />
      {renderMenuSection(getMenuLinks('raise') || [], 'raise')}
      <Divider variant="middle" sx={{ mx: 2, my: 1 }} />
      {!isOverMD && (
        <>
          {additionalLink && additionalLink.active && (
            <ListItemButton onClick={handleExtraLinkClick}>
              <ListItemText
                primaryTypographyProps={{ className: classes.listItemText }}
              >
                {additionalLink.title}
              </ListItemText>
            </ListItemButton>
          )}
          {renderMenuSection(getMenuLinks('public') || [])}
          {process.env.ENABLE_MULTILANGUAGE === 'true' && !isUserLogged && (
            <>
              <ListItemButton onClick={e => setAnchorEl(e.currentTarget)}>
                <ListItemIcon sx={{ minWidth: 43 }}>
                  <Language />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ className: classes.listItemText }}
                >
                  <FormattedMessage
                    messages={messages}
                    messageId={
                      LocalStorage.getItem('user-language') || DEFAULT_LOCALE
                    }
                  />
                </ListItemText>
              </ListItemButton>
              <Menu
                elevation={1}
                id="language-toggle"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {appLocales.map(language => (
                  <MenuItem
                    key={language}
                    value={language}
                    onClick={() => {
                      handleMenuItemClick(language);
                    }}
                  >
                    <FormattedMessage
                      messages={messages}
                      messageId={language}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          <Divider variant="middle" sx={{ mx: 2, my: 1 }} />
        </>
      )}
      {isUserLogged ? (
        <>
          {renderMenuSection(getMenuLinks('account') || [], 'account')}
          <LogoutButton />
        </>
      ) : (
        <ListItem sx={{ py: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Button
                href={composeRedirectUrl(REGISTER_ROUTE)}
                variant="secondary"
                fullWidth
              >
                <FormattedMessage messages={messages} messageId="signUp" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button href={composeRedirectUrl(LOGIN_ROUTE)} fullWidth>
                <FormattedMessage messages={messages} messageId="signIn" />
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </List>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle: language => dispatch(changeLocale(language)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(UserMenu);
