/**
 *
 * Logo
 *
 */

import Link from 'basic/Link';
import LogoImgStd from 'images/mamacrowd_logo_2022.svg';
import LogoImgBlack from 'images/logo_mamacrowd_black.svg';
import { HOME_PAGE_ROUTE } from 'utils/constants';

import { useStyle } from './Logo.style';

export interface LogoProps {
  variant?: 'standard' | 'black';
  maxWidth?: number | string;
}

function Logo({ variant = 'standard', maxWidth = '100%' }: LogoProps) {
  const classes = useStyle({ maxWidth });

  switch (variant) {
    case 'standard':
      return (
        <Link to={HOME_PAGE_ROUTE}>
          <img className={classes.logo} src={LogoImgStd} alt="mamacrowd-logo" />
        </Link>
      );
    case 'black':
      return (
        <Link to={HOME_PAGE_ROUTE}>
          <img
            className={classes.logo}
            src={LogoImgBlack}
            alt="mamacrowd-logo"
          />
        </Link>
      );
  }
}

export default Logo;
