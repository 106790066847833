/**
 *
 * Header
 *
 */

import React, { memo, useState, useEffect, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import {
  Popover,
  AppBar,
  Drawer,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mamacrowd/ui-kit';

import Box from 'components/Box';
import Logo from 'components/Logo';
import LocaleToggle from 'containers/LocaleToggle';
import FormattedMessage from 'components/FormattedMessage';

import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { LOGIN_ROUTE, REGISTER_ROUTE } from 'utils/constants';

import { loadAdditionalHeader } from './actions';
import { makeSelectHeaderAdditionalLink, makeSelectCalled } from './selectors';
import reducer from './reducer';
import saga from './saga';
import UserMenu from './UserMenu';
import HeaderMenu from './HeaderMenu';
import UserButton from './UserButton';
import { useStyle } from './Header.style';
import messages from './messages';

interface HideOnScrollProps {
  children: ReactElement;
}

function HideOnScroll({ children }: HideOnScrollProps) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const mapStateToProps = createStructuredSelector({
  additionalLink: makeSelectHeaderAdditionalLink(),
  callToExtraLinkDone: makeSelectCalled(),
});

export const mapDispatchToProps = dispatch => ({
  loadAdditionalLink: () => {
    dispatch(loadAdditionalHeader());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface HeaderProps extends PropsFromRedux {
  isInSec?: boolean;
  handleDrawerToggle: () => void;
}

export function Header({
  additionalLink,
  callToExtraLinkDone,
  isInSec = false,
  handleDrawerToggle,
  loadAdditionalLink,
}: HeaderProps) {
  useInjectReducer({ key: 'header', reducer });
  useInjectSaga({ key: 'header', saga });

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const theme = useTheme();
  const classes = useStyle({ isInSec });
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));
  const isUserLogged = User.isLogged();
  const { isPowerUser, firstName } = User.getUser() || {};

  useEffect(() => {
    if (
      !additionalLink &&
      loadAdditionalLink &&
      !callToExtraLinkDone &&
      !isInSec
    ) {
      loadAdditionalLink();
    }
  }, [loadAdditionalLink, additionalLink, callToExtraLinkDone]);

  useEffect(() => {
    if (Boolean(menuAnchor)) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'initial';
    }
  }, [menuAnchor]);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (Boolean(menuAnchor)) {
      setMenuAnchor(null);
    } else {
      setMenuAnchor(event.currentTarget);
    }
  };

  useEffect(
    () => () => {
      document.body.style.position = 'initial';
    },
    [],
  );

  const mobileMenuJSX = (
    <UserMenu additionalLink={additionalLink} isPowerUser />
  );

  return (
    <Box className={classes.header}>
      <HideOnScroll>
        <AppBar className={classes.headerContainer}>
          <Toolbar className={classes.headerItemsContainer}>
            {handleDrawerToggle && !isOverMD && (
              <IconButton
                size="medium"
                aria-label="menu"
                onClick={handleDrawerToggle}
                icon={<Menu color="secondary" />}
              />
            )}
            <Box>
              <Logo maxWidth={isOverMD ? 180 : 150} />
            </Box>
            {isOverMD && (
              <>
                <Box sx={{ mr: 'auto', ml: 2 }}>
                  {!isInSec && <HeaderMenu additionalLink={additionalLink} />}
                </Box>
                {/* eslint-disable prettier/prettier */
                  process.env.ENABLE_MULTILANGUAGE === 'true' && !isUserLogged && (
                    <Box>
                      <LocaleToggle
                        textLanguageBreakpoint="lg"
                        textColor={theme.palette.text.primary}
                      />
                    </Box>
                  )/* eslint-enable prettier/prettier */}
              </>
            )}
            <Box style={{ marginLeft: isOverMD ? 0 : 'auto' }}>
              {isUserLogged || !isOverMD ? (
                <UserButton
                  firstName={firstName}
                  isLogged={isUserLogged}
                  isPowerUser={isPowerUser}
                  isMenuOpen={Boolean(menuAnchor)}
                  onClick={handleUserMenu}
                />
              ) : (
                <Stack spacing={2} direction="row" alignItems="center">
                  <Button
                    href={composeRedirectUrl(LOGIN_ROUTE)}
                    variant="text"
                    fullWidth
                  >
                    <FormattedMessage messages={messages} messageId="signIn" />
                  </Button>
                  <Button
                    href={composeRedirectUrl(REGISTER_ROUTE)}
                    variant="secondary"
                    style={{ minWidth: 100 }}
                    fullWidth
                  >
                    <FormattedMessage messages={messages} messageId="signUp" />
                  </Button>
                </Stack>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor) && isOverMD}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        PaperProps={{ style: { minWidth: 300 } }}
      >
        {mobileMenuJSX}
      </Popover>
      <Drawer
        anchor="right"
        open={Boolean(menuAnchor) && !isOverMD}
        onClose={handleClose}
        PaperProps={{ style: { width: '100%', height: '100%' } }}
        style={{ zIndex: 2 }}
      >
        <Toolbar className={classes.header} />
        {mobileMenuJSX}
      </Drawer>
    </Box>
  );
}

export default compose(connector, memo)(Header);
