/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Header.UserMenu';

export default defineMessages({
  investSection: {
    id: `${scope}.invest_section.message`,
    defaultMessage: 'Invest',
  },
  raiseSection: {
    id: `${scope}.raise_section.message`,
    defaultMessage: 'Raise',
  },
  invest: {
    id: `${scope}.invest.message`,
    defaultMessage: 'All projects',
  },
  portfolio: {
    id: `${scope}.portfolio.message`,
    defaultMessage: 'Portfolio',
  },
  investGuide: {
    id: `${scope}.invest_guide.message`,
    defaultMessage: 'Invest Guide',
  },
  raise: {
    id: `${scope}.raise.message`,
    defaultMessage: 'Candidate a project',
  },
  campaigns: {
    id: `${scope}.campaigns.message`,
    defaultMessage: 'Campaigns',
  },
  raiseGuide: {
    id: `${scope}.raise_guide.message`,
    defaultMessage: 'Raise Guide',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
  profile: {
    id: `${scope}.profile.message`,
    defaultMessage: 'Profile',
  },
  watchlist: {
    id: `${scope}.watchlist.message`,
    defaultMessage: 'Followed',
  },
  sim: {
    id: `${scope}.sim.message`,
    defaultMessage: 'Sim',
  },
  monitoring: {
    id: `${scope}.monitoring.message`,
    defaultMessage: 'Monitoring Fondi',
  },
  companyReports: {
    id: `${scope}.company_reports.message`,
    defaultMessage: 'Monitoring',
  },
  earlyStageAdvisory: {
    id: `${scope}.early_stage_advisory.message`,
    defaultMessage: 'Early Stage Advisory',
  },
  console: {
    id: `${scope}.console.message`,
    defaultMessage: 'Console',
  },
  powerUser: {
    id: `${scope}.power_user.message`,
    defaultMessage: 'Power User',
  },
  signIn: {
    id: `${scope}.sign_in.message`,
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: `${scope}.sign_up.message`,
    defaultMessage: 'Sign Up',
  },
  it: {
    id: `${scope}.it.message`,
    defaultMessage: 'Italian',
  },
  en: {
    id: `${scope}.en.message`,
    defaultMessage: 'English',
  },
  beta: {
    id: `${scope}.beta.message`,
    defaultMessage: 'Beta',
  },
});
