import { createSelector } from 'reselect';
import { initialState, HeaderState } from './reducer';

/**
 * Direct selector to the header state domain
 */

export const selectHeaderDomain = (state: { header: HeaderState }) =>
  state.header || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Header
 */

const makeSelectHeader = () =>
  createSelector(selectHeaderDomain, substate => substate);

export const makeSelectLoading = () =>
  createSelector(selectHeaderDomain, substate => substate.loading);

export const makeSelectError = () =>
  createSelector(selectHeaderDomain, substate => substate.error);

export const makeSelectCalled = () =>
  createSelector(selectHeaderDomain, substate => substate.called);

export const makeSelectHeaderAdditionalLink = () =>
  createSelector(selectHeaderDomain, substate => substate.additionalLink);

export default makeSelectHeader;
