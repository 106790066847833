import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  maxWidth: number | string;
};

export const useStyle = makeStyles<Theme, Props>({
  logo: props => ({
    margin: 'auto',
    height: 'auto',
    width: '100%',
    maxWidth: props.maxWidth,
    verticalAlign: 'middle',
  }),
});
