import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  expandIcon: {
    transition: 'transform 300ms',
  },
  expandIconOpen: {
    transform: 'rotate(180deg)',
  },
  powerUserChip: {
    backgroundColor: theme.palette.common.white,
    height: 'auto',
    '& .MuiChip-label': {
      padding: '0 4px',
      fontSize: 12,
    },
  },
}));
