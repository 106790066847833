/**
 *
 * HeaderMenu
 *
 */
import { useEffect, useState, ReactNode } from 'react';

import { Stack } from '@mui/material';
import { Link } from '@mamacrowd/ui-kit';

import FormattedMessage from 'components/FormattedMessage';
import { trackEvent } from 'utils/trackers';

import { getMenuLinks, MenuLink } from '../HeaderLinks';
import messages from './messages';

// Tipo esteso per i link del menu desktop
type HeaderMenuLink = Omit<MenuLink, 'translationId'> & {
  translationId: ReactNode;
  isExtraLink?: boolean;
};

function HeaderMenu({ additionalLink }) {
  const [menuLinks, setMenuLinks] = useState<HeaderMenuLink[]>([]);

  useEffect(() => {
    if (additionalLink) {
      const newMenuLinks = getMenuLinks('desktop').map(l => ({
        translationId: (
          <FormattedMessage
            messages={messages}
            messageId={l.translationId as string}
          />
        ),
        link: l.link,
        isExtraLink: false,
      }));
      newMenuLinks.splice(2, 0, {
        translationId: additionalLink.title,
        link: additionalLink.url,
        isExtraLink: true,
      });
      setMenuLinks(newMenuLinks);
    }
  }, [additionalLink]);

  const handleExtraLinkClick = (e, link) => {
    if (link.isExtraLink) {
      e.preventDefault();
      trackEvent('header_extra_link_click', link.link, 'Nav');
      window.location.href = link.link;
    }
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      {menuLinks.map((link, index) => (
        <Link
          key={index}
          to={link.link}
          variant="body1"
          color="textPrimary"
          onClick={e => handleExtraLinkClick(e, link)}
        >
          {link.translationId}
        </Link>
      ))}
    </Stack>
  );
}

export default HeaderMenu;
