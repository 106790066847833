/*
 * CampaignsPage Messages
 *
 * This contains all the text for the CampaignsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CampaignsPage';

export default defineMessages({
  SEO_TITLE: {
    id: `${scope}.seo_title.message`,
    defaultMessage: 'All the campaigns',
  },
  SEO_DESCRIPTION: {
    id: `${scope}.seo_description.message`,
    defaultMessage:
      'All projects and companies selected by mamacrowd for equity crowdfunding investments',
  },
  SEO_KEYWORDS: {
    id: `${scope}.seo_keywords.message`,
    defaultMessage:
      'equity crowdfunding, investimento, startup, PMI, immobiliare, opportunità, socio',
  },
  OG_DESCRIPTION: {
    id: `${scope}.og_description.message`,
    defaultMessage: 'Discover the best companies and active campaigns',
  },
  campaignsPageTitle: {
    id: `${scope}.campaigns_page_title.message`,
    defaultMessage: 'Invest today in the biggest companies of tomorrow',
  },
  campaignsDecription: {
    id: `${scope}.campaigns_description.message`,
    defaultMessage: 'The most powerful equity crowdfunding in Italy',
  },
  income: {
    id: `${scope}.income.message`,
    defaultMessage: 'income',
  },
  realEstateIncomeTitle: {
    id: `${scope}.real_estate_income_title.message`,
    defaultMessage: 'Invest in Real Estate Income',
  },
  realEstateIncomeDescription: {
    id: `${scope}.real_estate_income_description.message`,
    defaultMessage:
      'Il crowdfunding Immobiliare a {incomeChip} è la nuova opportunità per investire in immobili già affittati, che offre rendimenti stabili, prima accessibili solo ai professionisti. A differenza delle operazioni di sviluppo immobiliare, si focalizza sulla gestione di immobili già esistenti, eliminando i rischi autorizzativi e di cantiere e riducendo il rischio complessivo. {guideLink}',
  },
  readGuide: {
    id: `${scope}.read_guide.message`,
    defaultMessage: 'Read our guide',
  },
  campaignsActive: {
    id: `${scope}.campaigns_active.message`,
    defaultMessage: 'Projects',
  },
  campaignsClosed: {
    id: `${scope}.campaigns_closed.message`,
    defaultMessage: 'Closed Projects',
  },
  noResults: {
    id: `${scope}.no_results.message`,
    defaultMessage: 'No results here',
  },
  getError: {
    id: `${scope}.get_error.message`,
    defaultMessage: 'Something went wrong loading the campaigns',
  },
  showMore: {
    id: `${scope}.show_more.message`,
    defaultMessage: 'Show more',
  },
  featuredCampaigns: {
    id: `${scope}.featured_campaigns.message`,
    defaultMessage: 'Featured Projects',
  },
  exclusiveCampaignsTitle: {
    id: `${scope}.exclusive_campaigns_title.message`,
    defaultMessage: 'Exclusive access for you',
  },
  exclusiveCampaignsDescription: {
    id: `${scope}.exclusive_campaigns_description.message`,
    defaultMessage: 'These projects are only available to you',
  },
  liveCampaigns: {
    id: `${scope}.live_campaigns.message`,
    defaultMessage: 'Live Projects',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
});
