/*
 *
 * Faq reducer
 *
 */
import produce from 'immer';
import { FEParsedError } from 'types/Error';
import {
  LOAD_ADDITIONAL_HEADER,
  LOAD_ADDITIONAL_HEADER_ERROR,
  LOAD_ADDITIONAL_HEADER_SUCCESS,
} from './constants';
import { HeaderActionTypes } from './actions';

export interface HeaderState {
  loading: boolean;
  error: FEParsedError | boolean;
  additionalLink: any | null;
  called: boolean;
}

export const initialState: HeaderState = {
  loading: false,
  additionalLink: null,
  called: false,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const headerReducer = (state = initialState, action: HeaderActionTypes) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ADDITIONAL_HEADER:
        draft.loading = true;
        break;
      case LOAD_ADDITIONAL_HEADER_SUCCESS:
        draft.additionalLink = action.headerLink;
        draft.called = true;
        break;
      case LOAD_ADDITIONAL_HEADER_ERROR:
        draft.error = action.error || true;
        draft.additionalLink = null;
        draft.called = true;
        break;
    }
  });

export default headerReducer;
