import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  liteContainer: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.blogArticlePlaceholder,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.common.white,
    },
    '& a:not([class^="MuiButton"])': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
