import { createTheme, Theme } from '@mui/material/styles';
import { typography, consoleTypography } from './typography';
import { components, consoleComponents } from './components';
import { mamacrowdPalette, synodiaPalette } from './palette';

const getPalette = () => {
  const THEME_TYPE = process.env.THEME_TYPE || 'mamacrowd';
  switch (THEME_TYPE) {
    case 'synodia':
      return synodiaPalette;
    case 'mamacrowd':
    default:
      return mamacrowdPalette;
  }
};

// Tema principale
const baseTheme = createTheme({
  palette: getPalette(),
  typography,
});

export const theme: Theme = createTheme(baseTheme, {
  components: components(baseTheme),
});

// Tema per la console
const consoleBaseTheme = createTheme({
  palette: getPalette(),
  typography: consoleTypography,
});

export const consoleTheme: Theme = createTheme(consoleBaseTheme, {
  components: consoleComponents(consoleBaseTheme),
});
