import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  appPageContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
});
