import makeStyles from '@mui/styles/makeStyles';
import { CONSOLE_SIDEBAR_WIDTH, CONSOLE_HEADER_HEIGHT } from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  sideBarMenuContainer: {
    width: CONSOLE_SIDEBAR_WIDTH,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    '@media print': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: `${theme.palette.secondary.light}AA`,
    },
  },
  list: {
    width: '100%',
    flexGrow: 1,
    '@media print': {
      display: 'none',
    },
  },
  primaryButton: {
    marginTop: 20,
  },
  versionNumber: {
    padding: '10px 16px',
  },
  drawerHeader: {
    minHeight: CONSOLE_HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  separator: {
    width: '100%',
    height: 30,
  },
}));
