/**
 *
 * ConsoleLayout
 *
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { consoleTheme } from 'theme';

import { Grid } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Box from 'components/Box';
import Header from 'containers/Header';
import SidebarMenu from 'console/components/SidebarMenu';
import CheckValidToken from 'components/CheckValidToken';
import Breadcrumbs from 'console/components/Breadcrumbs';

import { useStyle } from './ConsoleLayout.style';

export function ConsoleLayout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const classes = useStyle();

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={consoleTheme}>
        <Header handleDrawerToggle={handleDrawerToggle} isInSec />
        <Box style={{ display: 'flex' }}>
          <SidebarMenu
            showSidebar={mobileOpen}
            setShowSidebar={setMobileOpen}
          />
          <Box className={classes.contentContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Breadcrumbs />
              </Grid>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <CheckValidToken />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ConsoleLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ConsoleLayout;
