import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  pageContainer: {
    flexGrow: 1,
    '& a:not([class^="MuiButton"])': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
