/**
 *
 * LogoutButton
 *
 */

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ExitToApp from '@mui/icons-material/ExitToApp';

import FormattedMessage from 'components/FormattedMessage';
import { loadLogout } from 'containers/App/actions';

import { HOME_PAGE_ROUTE } from 'utils/constants';

import messages from './messages';
import { useStyle } from './LogoutButton.style';
// Definizione delle interfacce
interface LogoutButtonProps {
  loadLogoutData: () => void;
  logoutCallback?: () => void;
}

export function LogoutButton({
  loadLogoutData,
  logoutCallback,
}: LogoutButtonProps) {
  const classes = useStyle();
  const navigate = useHistory();

  const handleLogoutClick = () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    loadLogoutData();
    navigate.push(HOME_PAGE_ROUTE);

    if (typeof logoutCallback === 'function') {
      logoutCallback();
    }
  };

  return (
    <ListItemButton onClick={handleLogoutClick}>
      <ListItemIcon sx={{ minWidth: 30 }}>
        <ExitToApp color="error" style={{ width: 20, height: 20 }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: classes.listItemText }}
      >
        <FormattedMessage messages={messages} messageId="logout" />
      </ListItemText>
    </ListItemButton>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    loadLogoutData: () => {
      dispatch(loadLogout());
    },
  };
}

const withConnect = connect(undefined, mapDispatchToProps);

export default compose(withConnect)(LogoutButton);
