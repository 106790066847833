/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Header';

export default defineMessages({
  signIn: {
    id: `${scope}.sign_in.message`,
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: `${scope}.sign_up.message`,
    defaultMessage: 'Sign Up',
  },
  powerUser: {
    id: `${scope}.power_user.message`,
    defaultMessage: 'Power User',
  },
});
