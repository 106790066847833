/* eslint-disable prettier/prettier */

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

//-------------------------------------------------------------------------------------
// URL
//-------------------------------------------------------------------------------------

export const DOMAIN_URL = 'https://mamacrowd.com';

// WEB URL
export const HOME_PAGE_URL = '/home';
export const NEWSLETTER_SUBSCRIBE_URL = '/newsletter';
export const HOME_PAGE_METRICS_URL = '/home/metrics';
export const PAGE_URL = '/page';
export const FAQ_URL = '/faq';
export const NEWS_URL = '/news';
export const PRESS_URL = '/press/:type';
export const TESTIMONIAL_URL = '/testimonial';
export const CAMPAIGN_URL = '/campaign/:systemTitle';
export const CAMPAIGN_METRICS_URL = '/campaign/:campaignId/project/metrics';
export const CAMPAIGNS_ACTIVE_URL = '/campaign/active';
export const CAMPAIGNS_CLOSED_URL = '/campaign';
export const CAMPAIGN_PREVIEW_URL = '/campaign/:campaignId/preview/:previewStatus';
export const CAMPAIGN_INVEST_URL = '/campaign/:campaignId/investment-order';
export const CAMPAIGN_QUOTA_URL = '/campaign/:campaignId/quota';
export const CAMPAIGN_ROUND_URL = '/campaign/:campaignId/round';
export const CAMPAIGN_COMMENTS_URL = '/campaign/:campaignId/comment';
export const CAMPAIGN_COMMENT_URL = '/comment/:commentId';
export const CAMPAIGN_COMMENT_LIKE_URL = '/comment/:commentId/like';
export const CAMPAIGN_COMMENT_ABUSE_URL = '/comment/:commentId/abuse';
export const EQUITY_CALC_URL = '/investment-order/campaign/:systemTitle/calc/:value';
export const AUCAP_CALCULATION_URL = '/business-tools/capital-increase/calc';
export const PREVIEW_ANNEX_URL = '/business-tools/campaign/:campaignId/annex3/preview';
export const SETTINGS_URL = '/settings/:key';
export const INDUSTRY_URL = '/industry';
export const SINGLE_INDUSTRY_URL = '/industry/:industryId';
export const PARTNER_URL = '/partner';
export const PUB_COMPANY_FINANCIALS_URL = '/campaign/:id/company/financials';
export const STATISTICS_RE_URL = '/statistics/real-estate'

// AUTH URL
export const AUTH_LOGIN_URL = '/auth/login';
export const AUTH_LOGIN_TOKEN_URL = '/auth/login/token';
export const AUTH_REGISTER_URL = '/auth/register';
export const AUTH_MAMA_TKN_URL = '/auth/mama-tkn';
export const AUTH_ACTIVATION_MAIL_URL = '/auth/activate/:token';
export const AUTH_CONFIRM_MAIL_URL = '/auth/activate/email';
export const AUTH_FORGOT_URL = '/auth/forgot/:email';
export const AUTH_RESET_URL = '/auth/reset/:token';
export const AUTH_REFRESH_TOKEN_URL = '/auth/refresh';
export const AUTH_SOCIAL_FACEBOOK_LOGIN_URL = '/auth/social/facebook/login';
export const AUTH_SOCIAL_FACEBOOK_REGISTER_URL = '/auth/social/facebook/register/start';
export const AUTH_SOCIAL_LINKEDIN_LOGIN_URL = '/auth/social/linkedin/login';
export const AUTH_SOCIAL_LINKEDIN_ACCESS_URL = '/auth/social/linkedin/access/start';
export const AUTH_SOCIAL_LINKEDIN_REGISTER_URL = '/auth/social/linkedin/register/start';
export const AUTH_SOCIAL_GOOGLE_LOGIN_URL = '/auth/social/google/login';
export const AUTH_SOCIAL_GOOGLE_REGISTER_URL = '/auth/social/google/register/start';
export const AUTH_SOCIAL_REGISTER_COMPLETE_URL = '/auth/social/register/complete';

// UTIL URL
export const UTIL_COUNTRIES_LIST_URL = '/util/list-countries/';
export const UTIL_PARSE_FISCAL_CODE_URL = '/util/parseFiscalCode/:fiscalCode';

// LOG URL
export const LOG_ERROR_URL = '/log/put-log';

// ME URL - Anchor Investor
export const ME_ANCHOR_INVESTOR_URL = '/me/campaign/:campaignId/anchor-investor/:anchorInvestorId';
export const ME_ANCHOR_INVESTOR_CAMPAIGN_URL = '/me/campaign/:campaignId/anchor-investor';

// ME URL - Basic Profile
export const ME_BASIC_PROFILE_URL = '/me/basic-profile';

// ME URL - Business Profile
export const ME_BUSINESS_PROFILE_URL = '/me/business-profile';
export const ME_BUSINESS_PROFILE_VAT_NUMBER_URL = '/me/business-profile/vatNumber/:vatNumberId';

// ME URL - Campaign
export const ME_CAMPAIGN_URL = '/me/campaign/:campaignId';
export const ME_CAMPAIGN_INFO_URL = '/me/campaign/:campaignId/info';
export const ME_CAMPAIGN_ORDER_INFO_URL = '/me/campaign/:campaignId/order-info';
export const ME_CAMPAIGN_CALC_URL = '/me/campaign/:campaignId/calc/:value';
export const ME_CAMPAIGN_METRICS_URL = '/me/campaign/:campaignId/metrics';
export const ME_CAMPAIGN_DRAFT_URL = '/me/campaign/draft';
export const ME_CAMPAIGN_PRIVATE_URL = '/me/campaign/private';
export const ME_CAMPAIGN_RESTRICTED_URL = '/me/campaign/restricted/active';
export const ME_CAMPAIGN_ORDERS_URL = '/me/campaign/:campaignId/investment-order';
export const ME_CAMPAIGN_SHAREHOLDERS_URL = '/me/campaign/:campaignId/shareholder';
export const ME_CAMPAIGN_SHAREHOLDERS_REWARDS_URL = '/me/campaign/:campaignId/shareholder/reward';
export const ME_CAMPAIGN_SHAREHOLDERS_DOCUMENT_URL = '/me/campaign/:campaignId/shareholder/document/:documentId';
export const ME_CAMPAIGN_OWNER_STATUS_URL = '/me/campaign/:campaignId/owner-edit-status/:ownerEditStatus';
export const ME_CAMPAIGN_COMMUNICATION_URL = '/me/campaign/:campaignId/update/:updateId';
export const ME_CAMPAIGN_COMMUNICATIONS_URL = '/me/campaign/:campaignId/update/:audience';
export const ME_CAMPAIGN_TAX_BONUS_URL = '/me/campaign/:campaignId/update/shareholder/tax-bonus-sign';
export const ME_CAMPAIGN_TAX_BONUS_JOB_STATUS_URL = '/me/sign/campaign/:campaignId';
export const ME_CAMPAIGN_TAX_BONUS_DOCUSIGN_URL = '/me/sign/campaign/:campaignId/envelope/:envelopeId/url';
export const ME_CAMPAIGN_COMPANY_FINANCIALS = '/me/campaign/:campaignId/company/financials';

// ME URL - Campaign Obs
export const ME_CAMPAIGN_OBS_URL = '/me/campaign/:campaignId/obs/scene';

// ME URL - Campaign Application
export const ME_CAMPAIGN_APPLICATION_URL = '/me/campaign-application/:applicationId';
export const ME_CAMPAIGN_APPLICATION_LEAD_URL = '/me/campaign-application/:applicationId/lead';
export const ME_CAMPAIGN_LEAD_STEP_2_SEND_URL = '/me/campaign-application/:leadId/lead/step-2'
export const ME_CAMPAIGN_APPLICATION_LEAD_SEND_URL = '/me/campaign-application/:applicationId/lead/step-1';
export const ME_CAMPAIGN_APPLICATION_RICHDATA_URL = '/me/campaign-application/richdata/:vatNumber/:subjectId';

// ME URL - Change Password
export const ME_CHANGE_PASSWORD_URL = '/me/changePassword';

// ME URL - Company
export const ME_COMPANY_URL = '/me/company/:companyId';
export const ME_COMPANY_RICHDATA_URL = '/me/company/:companyId/richdata';

// ME URL - Company Monitoring
export const ME_COMPANY_MONITORING_URL = '/me/company-monitoring/survey/:surveyId';
export const ME_COMPANY_MONITORING_ANSWER_POST_URL = '/me/company-monitoring/survey/:surveyId/answer';

// ME URL - Company Monitoring Report
export const ME_COMPANY_MONITORING_PROFILE_URL = '/me/company-monitoring-profile';
export const ME_COMPANY_MONITORING_PROFILE_REPORT_PERIODS_URL = '/me/company-monitoring-profile/:profileId/report/periods';
export const ME_COMPANY_MONITORING_PROFILE_REPORT_URL = '/me/company-monitoring-profile/:profileId/report';
export const ME_COMPANY_MONITORING_PROFILE_REPORT_DOCUMENTS_URL = '/me/company-monitoring-profile/:profileId/download-report-docs';

// ME URL - Cookies
export const ME_COOKIE_SETTINGS_URL = '/me/user/cookie-choices';

// ME URL - Data Board
export const ME_DATA_CAMPAIGN_CUMULATIVE_ORDER_URL = '/me/data-board/campaign/:campaignId/cumulative-order/charts/:period';
export const ME_DATA_CAMPAIGN_ORDER_URL = '/me/data-board/campaign/:campaignId/order/charts/:period';
export const ME_DATA_CAMPAIGN_FORECAST_URL = '/me/data-board/campaign/:campaignId/order/forecast';
export const ME_DATA_CAMPAIGN_USER_AGE_URL = '/me/data-board/campaign/:campaignId/user/age-stats';
export const ME_DATA_CAMPAIGN_USER_JOB_URL = '/me/data-board/campaign/:campaignId/user/job-stats';
export const ME_DATA_CAMPAIGN_METRIC_URL = '/me/data-board/campaign/:campaignId/metrics-stats';
export const ME_DATA_CAMPAIGN_INVESTORS_EDUCATION_URL = '/me/data-board/campaign/:campaignId/investors-count/mifid/education';
export const ME_DATA_CAMPAIGN_INVESTORS_GENRE_URL = '/me/data-board/campaign/:campaignId/investors-count/genre';
export const ME_DATA_CAMPAIGN_INVESTORS_QUOTA_URL = '/me/data-board/campaign/:campaignId/investors-count/quota-label';
export const ME_DATA_CAMPAIGN_INVESTORS_KNOWLEDGE_URL = '/me/data-board/campaign/:campaignId/investors-count/mifid/knowledge';
export const ME_DATA_CAMPAIGN_UTM_COUNT_USER_URL = '/me/data-board/campaign/:campaignId/user/count/utm-assignment';
export const ME_DATA_CAMPAIGN_FUNNEL_URL = '/me/data-board/campaign/:campaignId/funnel';
export const ME_DATA_CAMPAIGN_FUNNEL_BENCHMARK_URL = '/me/data-board/campaign/:campaignId/funnel/benchmark';


// ME URL - Description Section
export const ME_DESCRIPTION_SECTION_URL = '/me/campaign/:campaignId/description-section/:descriptionSectionId';
export const ME_DESCRIPTION_SECTION_CAMPAIGN_URL = '/me/campaign/:campaignId/description-section';

// ME URL - Disquis
export const ME_DISQUS_URL = '/me/disqus';

// ME URL - Follow
export const ME_FOLLOW_URL = '/me/follows/:campaignId';

// ME URL - EarlyBird
export const ME_EARLY_BIRD_URL = '/me/early-bird/:campaignId';

// ME URL - Info
export const ME_INFO_CAMPAIGN_URL = '/me/info/campaign';

// ME URL - Investment Order
export const ME_INVESTMENT_ORDER_URL = '/me/investment-order/:investmentOrderId';
export const ME_INVESTMENT_ORDER_PISP_URL = '/me/investment-order/:investmentOrderId/pisp';
export const ME_INVESTMENT_ORDER_RECEIPT_URL = '/me/investment-order/:investmentOrderId/download-receipt';
export const ME_INVESTMENT_ORDER_PENDING_URL = '/me/investment-order/pending'; // DEPRECATED
export const ME_INVESTMENT_ORDER_CAMPAIGN_URL = '/me/investment-order/campaign/:campaignId';

// ME URL - Feedback
export const ME_USER_FEEDBACK_URL = '/me/action/order-feedback';

// ME URL - Mifid
export const ME_MIFID_CURRENT_URL = '/me/mifid/current';
export const ME_MIFID_URL = '/me/mifid/:mifidId';

// ME ESCP - ESCP INVESTOR KNOWLEDGE
export const ME_ECSP_SURVEY_CURRENT_URL = '/me/iktest/ecsp/fiscalCode/:fiscalCode/current';
export const ME_ECSP_SURVEY_POST_URL = '/me/iktest/ecsp';
export const ME_ECSP_SURVEY_PATCH_URL = '/me/iktest/ecsp/:ecspId';


// ME URL - Newsletter
export const ME_NEWSLETTER_URL = '/me/newsletter';

// ME URL - Page
export const ME_PAGE_URL = '/me/page/:systemTitle'; // DEPRECATED

// ME URL - Pisp
export const ME_PISP_HELTH_URL = '/me/pisp/health';
export const ME_PISP_BANK_SERVICES_URL = '/me/pisp/debtor/:iban';
export const ME_PISP_PAYMENT_URL = '/me/pisp/payment';

// ME URL - Portfolio
export const ME_PORTFOLIO_URL = '/me/portfolio';
export const ME_PORTFOLIO_TAXATION_URL = '/me/portfolio/fiscalcode/:fiscalCode/taxation';
export const ME_PORTFOLIO_TAXATION_DETAIL_URL = '/me/portfolio/fiscalcode/:fiscalCode/taxation/year/:year';
export const ME_PORTFOLIO_DETAIL_URL = '/me/portfolio/fiscalcode/:fiscalCode/campaign/:campaignId';
export const ME_PORTFOLIO_CAMPAIGN_SIM_STATUS_URL = '/me/portfolio/fiscalcode/:fiscalCode/campaign/:campaignId/sim-status/:status';
export const ME_PORTFOLIO_SIM_ACCOUNT_NUMBER_URL = '/me/portfolio/sim-account/:simAccountId/:accountNumber';
export const ME_CDT_LIST_ALL_URL = '/me/portfolio/cdt';
export const ME_CREATE_CDT_URL = '/me/cdt-account';

// ME URL - Power User
export const ME_POWER_USER_URL = '/me/power-user';

// ME URL - Privacy Setting
export const ME_PRIVACY_SETTINGS_URL = '/me/privacy-settings';
export const ME_NOTIFICATION_SETTINGS_URL = '/me/notification-settings';

// ME URL - Quota Def
export const ME_QUOTA_URL = '/me/quota-def/:quotaId';
export const ME_QUOTA_CAMPAIGN_URL = '/me/quota-def/campaign/:campaignId';

// ME URL - Reward
export const ME_REWARD_URL = '/me/campaign/:campaignId/reward/:rewardId';
export const ME_REWARD_CAMPAIGN_URL = '/me/campaign/:campaignId/reward';

// ME URL - Richdata
export const ME_RICHDATA_POSITIONS_URL = '/me/richdata/positions';
export const ME_RICHDATA_USER_PROFILE_URL = '/me/richdata/user-profile?dbnotexpire=true';

// ME URL - Spid
export const ME_VALIDATION_SPID_URL = '/me/spid';

// ME URL - Shareholder
export const ME_SHAREHOLDER_DOCUMENT_URL = '/me/shareholder-document/:documentId';

// ME URL - Team Member
export const ME_TEAM_MEMBER_URL = '/me/team-member/:teamMemberId';

// ME URL - Team Member (Campaign Owner)
export const ME_CAMPAIGN_TEAM_MEMBER_URL = '/me/campaign/:campaignId/team-member/:teamMemberId';
export const ME_CAMPAIGN_TEAM_MEMBER_SORT_URL = '/me/campaign/:campaignId/team-member/sorting';

// ME URL - Upload Doc
export const ME_UPLOAD_DOCUMENT_URL = '/me/upload-doc';

// ME URL - Upload Video
export const ME_UPLOAD_VIDEO_URL = '/me/campaign/:campaignId/youtube-video';

// ME URL - User
export const ME_USER_URL = '/me/user';
export const ME_USER_UTM_URL = '/me/user/utm';
export const ME_USER_DOCUMENT_URL = '/me/user/idCard';
export const ME_USER_MINIBOND_SURVEY_URL = '/me/user/survey-minibond/:answer';
export const ME_USER_DATA_URL = '/me/user/data';
export const ME_USER_DATA_COMPANY_SHARES_URL = '/me/user/data/entity-profile/shares/natural_person';
export const ME_USER_DATA_COMPANY_SHARES_DETAILS_URL = '/me/user/data/entityProfileById/:vatNumber/:entityId';
export const ME_USER_INFO_URL = '/me/info/campaign/:campaignId';
export const ME_USER_ENTITY_URL = '/me/user-entity';

// ME URL - User Profile
export const ME_USER_PROFILE_URL = '/me/user-profile';

// ME URL - User Log
export const ME_USER_LOG_URL = '/me/log/action/:action';

// ME URL - Web Message
export const ME_WEB_MESSAGE_URL = '/me/web-message';

// CONSOLE URL - Anchor Investor
export const SEC_ANCHOR_INVESTOR_URL = '/sec/anchor-investor/:anchorInvestorId';
export const SEC_ANCHOR_INVESTOR_CAMPAIGN_URL = '/sec/campaign/:campaignId/anchor-investor';
export const SEC_ANCHOR_INVESTOR_CAMPAIGN_IMPORT_URL = '/sec/campaign/:campaignId/anchor-investor';

// CONSOLE URL - Banner
export const SEC_BANNER_URL = '/sec/banner/:bannerId';

// CONSOLE URL - Blog Article
export const SEC_BLOG_ARTICLE_URL = '/sec/news/:articleId';
export const SEC_PATCH_BLOG_ARTICLE_IS_PUBLISHED_URL = '/sec/news/:articleId/is-published/:isPublished';
export const SEC_BLOG_ARTICLE_CARD_URL = '/sec/news/:articleId/html-card';

// CONSOLE URL - Business Profile
export const SEC_BUSINESS_PROFILE_URL = '/sec/business-profile/:businessProfileId';
export const SEC_BUSINESS_PROFILE_VALIDATION_URL = '/sec/business-profile/:businessProfileId/validation/:status';

// CONSOLE URL - Campaign
export const SEC_CAMPAIGN_URL = '/sec/campaign/:campaignId';
export const SEC_CAMPAIGN_INFO_URL = '/sec/campaign/:campaignId/info';
export const SEC_CAMPAIGN_ORDER_INFO_URL = '/sec/campaign/:campaignId/order-info';
export const SEC_CAMPAIGN_CALC_URL = '/sec/campaign/:campaignId/calc/:value';
export const SEC_CAMPAIGN_STATUS_CHECK_URL = '/sec/campaign/:campaignId/change-status-check/:status';
export const SEC_CAMPAIGN_STATUS_PATCH_URL = '/sec/campaign/:campaignId/status/:status';
export const SEC_CAMPAIGN_CARD_URL = '/sec/campaign/:campaignId/html-card';
export const SEC_CAMPAIGN_ALL_TITLES_URL = '/sec/campaign/all-titles';
export const SEC_CAMPAIGN_ECONOMICS_URL = '/sec/campaign/metrics';
export const SEC_CAMPAIGN_OPERATIONS_URL = '/sec/campaign/:campaignId/operations';
export const SEC_CAMPAIGN_PROJECT_METRICS_URL = '/sec/campaign/:campaignId/project/metrics';
export const SEC_CAMPAIGN_CLOSED_URL = '/sec/campaign/:campaignId/closed';
export const SEC_CAMPAIGN_FINALIZED_URL = '/sec/campaign/:campaignId/finalized';
export const SEC_CAMPAIGN_FINALIZED_SUMMARY_URL = '/sec/campaign/:campaignId/finalize-summary';
export const SEC_CAMPAIGN_PRE_RELEASE_URL = '/sec/campaign/:campaignId/pre-release';
export const SEC_CAMPAIGN_SHAREHOLDERS_URL = '/sec/campaign/:campaignId/shareholder';
export const SEC_CAMPAIGN_SHAREHOLDERS_DRAFT_URL = '/sec/campaign/:campaignId/shareholder/draft';
export const SEC_CAMPAIGN_SIM_ALL_TITLES_URL = '/sec/campaign/sim-all-titles';
export const SEC_CAMPAIGN_SIM_ACCOUNT_URL = '/sec/campaign/:campaignId/sim-account/:simAccountId';
export const SEC_CAMPAIGN_SIM_USER_URL = '/sec/campaign/:campaignId/sim-user';
export const SEC_CAMPAIGN_SIM_REQUESTED_URL = '/sec/campaign/:campaignId/sim-requested';
export const SEC_CAMPAIGN_SIM_ACCOUNT_UPLOAD_URL = '/sec/campaign/:campaignId/sim-update-account';
export const SEC_CAMPAIGN_MULTIPLE_SIM_URL = '/sec/campaign/:campaignId/check-multiple-sim-investment';
export const SEC_CAMPAIGN_MULTIPLE_PROF_URL = '/sec/campaign/:campaignId/check-multiple-professional-investment'
export const SEC_CAMPAIGN_SHAREHOLDERS_REWARDS_URL = '/sec/campaign/:campaignId/shareholder/reward';
export const SEC_CAMPAIGN_SHAREHOLDERS_REWARDS_DRAFT_URL = '/sec/campaign/:campaignId/shareholder/draft/reward';
export const SEC_CAMPAIGN_EARLY_BIRD_URL = '/sec/campaign/:campaignId/early-bird';
export const SEC_CAMPAIGN_RICHDATA_URL = '/sec/campaign/:campaignId/richdata';
export const SEC_CAMPAIGN_BILLING_PARAMS_URL = '/sec/campaign/:campaignId/billing-parameters';
export const SEC_CAMPAIGN_BILLING_PARAMS_EXTRA_URL = '/sec/campaign/:campaignId/billing-parameters/extra/:extraId';
export const SEC_CAMPAIGN_BILLING_URL = '/sec/campaign/:campaignId/billing';
export const SEC_CAMPAIGN_COMPANY_FINANCIALS = '/sec/campaign/:campaignId/company/financials';
export const SEC_CAMPAIGN_DOCUMENT_VALIDATION_URL = '/sec/campaign/:campaignId/user-profile/validations';

// CONSOLE URL - Campaign Video
export const SEC_UPLOAD_VIDEO_URL = '/sec/campaign/:campaignId/youtube-video';

// CONSOLE URL - Campaign Obs
export const CONSOLE_CAMPAIGN_OBS_URL = '/sec/campaign/:campaignId/obs/scene';

// CONSOLE URL - Company
export const SEC_COMPANY_URL = '/sec/company/:companyId';
export const SEC_COMPANY_ALL_TITLES_URL = '/sec/company/allBusinessName';

// CONSOLE URL - Company Monitoring
export const SEC_COMPANY_MONITORING_URL = '/sec/company-monitoring/company/:companyId';
export const SEC_COMPANY_MONITORING_PROFILES_URL = '/sec/company-monitoring/profile';

// CONSOLE URL - Competitor Campaign
export const SEC_COMPETITOR_URL = '/sec/competitor-campaign/:competitorId';
export const SEC_COMPETITOR_RESYNC_URL = '/sec/competitor-campaign/:competitorId/scraping-status/resync';

// CONSOLE URL - Description Section
export const SEC_DESCRIPTION_SECTION_URL = '/sec/description-section/:descriptionSectionId';
export const SEC_DESCRIPTION_SECTION_CAMPAIGN_URL = '/sec/description-section/campaign/:campaignId';

// CONSOLE URL - Document
export const SEC_DOCUMENT_URL = '/sec/document/:documentId';
export const SEC_DOCUMENT_CAMPAIGN_URL = '/sec/document/campaign/:campaignId';
export const SEC_DOCUMENT_SORT_URL = '/sec/document/campaign/:campaignId/sort';

// CONSOLE URL - Faq
export const SEC_FAQ_URL = '/sec/faq/:faqId';

// CONSOLE URL - Follow
export const SEC_FOLLOW_CAMPAIGN_URL = '/sec/follow/campaign/:campaignId';

// CONSOLE URL - Industry
export const SEC_INDUSTRY_URL = '/sec/industry/:industryId';

// CONSOLE URL - Investment Order
export const SEC_ORDER_URL = '/sec/investment-order/:orderId';
export const SEC_ORDER_SEARCH_URL = '/sec/investment-order/search';
export const SEC_ORDER_NOT_COMPLETED_URL = '/sec/investment-order/not-completed';
export const SEC_ORDER_CAMPAIGN_URL = '/sec/investment-order/campaign/:campaignId';
export const SEC_ORDER_RECEIPT_URL = '/sec/investment-order/:orderId/receipt';
export const SEC_ORDER_PROFESSIONAL_STATUS_URL = '/sec/investment-order/:orderId/professional/:status';
export const SEC_ORDER_SIM_STATUS_URL = '/sec/investment-order/:orderId/sim-status/:status';
export const SEC_ORDER_VALIDATION_URL = '/sec/investment-order/:id/validation/:validation';
export const SEC_ORDER_CANCEL_URL = '/sec/investment-order-cancel/:orderId/reason/:reason';
export const SEC_ORDER_SOURCE_URL = '/sec/investment-order/:orderId/source';
export const SEC_ANONYMIZED_INVESTMENT_ORDER_URL = '/sec/investment-order/anonymized';

// CONSOLE URL - Billing
export const SEC_BILLING_URL = '/sec/investment-order/anonymized-v2'

// CONSOLE URL - Media
export const SEC_MEDIA_URL = '/sec/media/:mediaId';

// CONSOLE URL - Notification
export const SEC_NOTIFICATION_URL = '/sec/notification/:notificationId/content';
export const SEC_NOTIFICATION_URL_CHECKER_URL = '/sec/notification/urlchecker';
export const SEC_NOTIFICATION_LATE_PAYMENT_URL = '/sec/notification/recall-late-payments';
export const SEC_NOTIFICATION_LATE_PAYMENT_ORDER_URL = '/sec/notification/recall-late-payment/investment-order/:investmentOrderId';
export const SEC_NOTIFICATION_DOCUMENT_ORDER_URL = '/sec/notification/recallUploadDocuments/investmentOrder/:orderId';
export const SEC_NOTIFICATION_CUSTOM_URL = '/sec/notification/custom';
export const SEC_NOTIFICATION_CUSTOM_AUDIENCE_URL = '/sec/user-audience/:audience';
export const SEC_NOTIFICATION_EMAIL_TEMPLATE_URL = '/sec/notification/list-email-template';
export const SEC_NOTIFICATION_USER_URL = '/sec/notification/user/:userId';
export const SEC_NOTIFICATION_CAMPAIGN_CLOSING_OWNER_URL = '/sec/notification/campaign/:campaignId/closing-to-campaign-owner';
export const SEC_NOTIFICATION_CAMPAIGN_CLOSING_NOT_PAID_URL = '/sec/notification/campaign/:campaignId/closing-investors-not-paid';
export const SEC_NOTIFICATION_CAMPAIGN_CLOSING_PAID_URL = '/sec/notification/campaign/:campaignId/closing-investors-paid';
export const SEC_NOTIFICATION_CAMPAIGN_ENDING_OWNER_URL = '/sec/notification/campaign/:campaignId/ending-to-campaign-owner';
export const SEC_NOTIFICATION_CAMPAIGN_ENDING_NOT_PAID_URL = '/sec/notification/campaign/:campaignId/ending-investors-not-paid';
export const SEC_NOTIFICATION_CAMPAIGN_ENDING_PAID_URL = '/sec/notification/campaign/:campaignId/ending-investors-paid';
export const SEC_NOTIFICATION_CAMPAIGN_STATUS_URL = '/sec/notification/campaign/:campaignId/status-notification';
export const SEC_NOTIFICATION_CAMPAIGN_FOLLOWER_URL = '/sec/notification/campaign/:campaignId/followers';
export const SEC_NOTIFICATION_CAMPAIGN_CUSTOM_MKT_URL = '/sec/notification/campaign/:campaignId/custom-mkt';

// CONSOLE URL - Page
export const SEC_PAGE_URL = '/sec/page/:pageId';

// CONSOLE URL - Partner
export const SEC_PARTNER_URL = '/sec/partner/:partnerId';
export const SEC_PARTNER_ALL_TITLES_URL = '/sec/partner/allNames';

// CONSOLE URL - Press
export const SEC_PRESS_URL = '/sec/press/:pressId';

// CONSOLE URL - Quota Def
export const SEC_QUOTA_URL = '/sec/quota-def/:quotaId';
export const SEC_QUOTA_CAMPAIGN_URL = '/sec/quota-def/campaign/:campaignId';

// CONSOLE URL - Refund
export const SEC_ORDER_REFUND_URL = '/sec/refund/:refundId';
export const SEC_REFUND_ORDER_REASON_URL = '/sec/refund/:orderId/reason/:reason';

// CONSOLE URL - Report
export const SEC_REPORT_LATE_PAYMENT_URL = '/sec/report/late-payments';
export const SEC_REPORT_CAMPAIGN_ORDER_URL = '/sec/report/campaign/:campaignId/investment-orders';
export const SEC_REPORT_CAMPAIGN_ORDER_BANK_URL = '/sec/report/campaign/:campaignId/investment-orders-for-bank';
export const SEC_REPORT_CAMPAIGN_ORDER_RECEIPT_URL = '/sec/report/campaign/:campaignId/investment-orders-receipt-zip';
export const SEC_REPORT_CAMPAIGN_ORDER_RECEIPT_BANK_URL = '/sec/report/campaign/:campaignId/investment-orders-receipt-zip-for-bank';

// CONSOLE URL - Reward
export const SEC_REWARD_URL = '/sec/reward/:rewardId';
export const SEC_REWARD_CAMPAIGN_URL = '/sec/reward/campaign/:campaignId';

// CONSOLE URL - Richdata
export const SEC_RICHDATA_VAT_NUMBER_URL = '/sec/richdata/vatNumber/:vatNumberId';
export const SEC_RICHDATA_ENTITY_SEARCH_URL = '/sec/richdata/entity-search-by-fiscal-code/:fiscalCode';
export const SEC_RICHDATA_ENTITY_PROFILE_URL = '/sec/richdata/entity-profile/:fiscalCode/:subjectId';
export const SEC_RICHDATA_ENTITY_CONNECTIONS_URL = '/sec/richdata/entity-profile-connections/:fiscalCode/:subjectId';
export const SEC_RICHDATA_ENTITY_BUSINESS_PARTNERS_URL = '/sec/richdata/business-partners/:fiscalCode/:subjectId';
export const SEC_RICHDATA_ENTITY_RISKS_NEGATIVE_EVENTS_URL = '/sec/richdata/risks-negative-events/:fiscalCode/:subjectId';
export const SEC_RICHDATA_ENTITY_RISKS_NEGATIVE_EVENTS_FLAG_URL = '/sec/richdata/risks-negative-events-flag/:fiscalCode/:subjectId';
export const SEC_RICHDATA_ENTITY_ALL_BALANCESHEET_URL = '/sec/richdata/balancesheet/all/:fiscalCode/:subjectId';

// CONSOLE URL - Setting
export const SEC_SETTING_URL = '/sec/settings/:key';
export const SEC_EMAIL_SERVICE_STATUS_URL = '/sec/settings/email-service/:status';


// CONSOLE URL - Team Member
export const SEC_TEAM_MEMBER_URL = '/sec/team-member/:teamMemberId';
export const SEC_TEAM_MEMBER_CAMPAIGN_URL = '/sec/team-member/campaign/:campaignId';
export const SEC_TEAM_MEMBER_SORT_URL = '/sec/team-member/campaign/:campaignId/sort';

// CONSOLE URL - Testimonial
export const SEC_TESTIMONIAL_URL = '/sec/testimonial/:testimonialId';

// CONSOLE URL - Translator
export const SEC_TRANSLATOR_URL = '/sec/translator/translation';

// CONSOLE URL - Update
export const SEC_UPDATE_URL = '/sec/update/:updateId';
export const SEC_UPDATE_CAMPAIGN_URL = '/sec/campaign/:campaignId/update/:audience';
export const SEC_UPDATE_CARD_URL = '/sec/campaign/:campaignId/update/html-card';

// CONSOLE URL - Communication
export const SEC_COMMUNICATION_URL = '/sec/update/:updateId'; // MC2: POST|PUT :updateId => :audience
export const SEC_COMMUNICATION_VALIDATION_URL = '/sec/update/:updateId/validation/:status';
export const SEC_COMMUNICATION_CAMPAIGN_URL = '/sec/campaign/:campaignId/update/:audience';

// CONSOLE URL - Upload
export const SEC_UPLOAD_PRIVATE_USER_URL = '/sec/upload/private/user/:userId';
export const SEC_UPLOAD_PRIVATE_CAMPAIGN_URL = '/sec/upload/private/campaign/:campaignId';
export const SEC_UPLOAD_PUBLIC_ASSET_URL = '/sec/upload/public/asset';
export const SEC_UPLOAD_PUBLIC_CAMPAIGN_URL = '/sec/upload/public/campaign/:campaignId';
export const SEC_UPLOAD_PUBLIC_CAMPAIGN_UPDATE_URL = '/sec/upload/public/campaign/:campaignId/update/:updateId';
export const SEC_UPLOAD_PUBLIC_CAMPAIGN_DESCRIPTION_SECTION_URL = '/sec/upload/public/campaign/:campaignId/descriptionSection/:descriptionSectionId';

// CONSOLE URL - User
export const SEC_USER_URL = '/sec/user/:userId';
export const SEC_USER_SEARCH_URL = '/sec/user/search';
export const SEC_USER_SEARCH_ANALYST_URL = '/sec/user/search-analysts/';
export const SEC_USER_ANONYMIZER_URL = '/sec/user/:userId/anonymizer';
export const SEC_USER_ACTIVATE_URL = '/sec/user/:userId/activate';
export const SEC_USER_NOTIFICATION_SETTINGS_URL = '/sec/user/:userId/notificationSettings';
export const SEC_USER_PRIVACY_SETTINGS_URL = '/sec/user/:userId/privacy-settings';
export const SEC_USER_BLOCK_URL = '/sec/user/:userId/block';
export const SEC_USER_IMPERSONIFICATION_URL = '/sec/user/:userId/impersonification';

// CONSOLE URL - User Profile
export const SEC_USER_PROFILE_URL = '/sec/user-profile/:userProfileId';
export const SEC_USER_PROFILE_VALIDATION_URL = '/sec/user-profile/:userProfileId/validation/:status';

// CONSOLE URL - User Role
export const SEC_USER_ROLE_URL = '/sec/user-role/';

// CONSOLE URL - Web Message
export const SEC_MESSAGE_URL = '/sec/web-message/:messageId';

// CONSOLE URL - Widget
export const SEC_WIDGET_CAMPAIGN_URL = '/sec/campaign/:campaignId/widget';

// CONSOLE URL - Campaign Application
export const SEC_GET_CAMPAIGN_APPLICATION_LIST_URL = '/sec/campaign-application';
export const SEC_GET_CAMPAIGN_APPLICATION_URL = '/sec/campaign-application/:applicationId';
export const SEC_DELETE_CAMPAIGN_APPLICATION_URL = '/sec/campaign-application/:applicationId';
export const SEC_GET_CAMPAIGN_APPLICATION_LIST_FILTERED_URL = '/sec/campaign-application/search';
export const SEC_POST_ACCEPT_APPLICATION_URL = '/sec/campaign-application/:applicationId/accepted';

// CONSOLE URL - Hubspot Deal Application
export const SEC_GET_HUBSPOT_DEAL_CAMPAIGN_APPLICATION_URL = '/sec/hubspot/deal/:dealId';

// BANCASELLA URL - Campaign
export const SEC_SELLA_CAMPAIGN_URL = '/sec/bancasella/campaign/:campaignId/bancasella';
export const SEC_SELLA_CAMPAIGN_ORDER_URL = '/sec/bancasella/campaign/:campaignId/bancasella-order-status';
export const SEC_SELLA_CAMPAIGN_ORDER_PAYER_NAME_MATCH_STATUS_URL = '/sec/bancasella/payment/payer-name-match-status';
export const SEC_SELLA_CAMPAIGN_PRE_RELEASE_URL = '/sec/bancasella/campaign/:campaignId/pre-release/:targetAmount';
export const SEC_SELLA_CAMPAIGN_ORDER_WAITING_URL = '/sec/bancasella/campaign/:campaignId/order/waiting';
export const SEC_SELLA_CAMPAIGN_FROZEN_URL = '/sec/bancasella/campaign/:campaignId/frozen';
export const SEC_SELLA_CAMPAIGN_FINALIZE_SUMMARY_URL = '/sec/bancasella/campaign/:campaignId/finalize-summary';
export const SEC_SELLA_CAMPAIGN_FINALIZED_URL = '/sec/bancasella/campaign/:campaignId/finalized';
export const SEC_SELLA_CAMPAIGN_FINALIZED_RESERVED_FEE_URL = '/sec/bancasella/campaign/:campaignId/finalized-reserved-fee';

// BANCASELLA URL - Company
export const SEC_SELLA_COMPANY_URL = '/sec/bancasella/company';
export const SEC_SELLA_COMPANY_CAMPAIGN_URL = '/sec/bancasella/sellacompany/:sellaCompanyId/campaign/:campaignId';

// BANCASELLA URL - Order
export const SEC_SELLA_ORDER_URL = '/sec/bancasella/order/:orderId';
export const SEC_SELLA_ORDER_REASON_URL = '/sec/bancasella/order/:orderId/:reason';

// BANCASELLA URL - Payment
export const SEC_SELLA_PAYMENT_URL = '/sec/bancasella/payment/:orderId/:reason';

// BANCASELLA URL - Notify
export const SEC_SELLA_NOTIFY_ORDER_URL = '/sec/bancasella/notify-unidentified/:orderId';

// BANCASELLA URL - Unidentified Payment
export const SEC_UNIDENTIFIED_PAYMENT_URL = '/sec/unidentified-payment';
export const SEC_UNIDENTIFIED_PAYMENT_CONNECT_URL = '/sec/unidentified-payment/:unidentifiedId/ref-old-id/:refOldId';
export const SEC_UNIDENTIFIED_PAYMENT_DELETED_URL = '/sec/unidentified-payment/:unidentifiedId';

// DASHBOARD URL - Annual Budget
export const SEC_DATA_ANNUAL_BUDGET_URL = '/sec/data-board/annual-budget';

// DASHBOARD URL - Today
export const SEC_DATA_TODAY_METRICS_URL = '/sec/data-board/today/metrics';

// DASHBOARD URL - User
export const SEC_DATA_USER_METRICS_URL = '/sec/data-board/user/metrics/:period';
export const SEC_DATA_USER_CHART_URL = '/sec/data-board/user/charts/:period';
export const SEC_DATA_USER_STATS_URL = '/sec/data-board/user/stats';
export const SEC_DATA_USER_ORDER_STATS_URL = '/sec/data-board/user/investment-order/stats';
export const SEC_DATA_USER_AGE_STATS_URL = '/sec/data-board/user/age-stats';
export const SEC_DATA_USER_JOB_STATS_URL = '/sec/data-board/user/job-stats';
export const SEC_DATA_USER_UTM_STATS_URL = '/sec/data-board/user/utm-stats';
export const SEC_DATA_USER_NEWSLETTER_STATS_URL = '/sec/data-board/user/newsletter-stats';

// DASHBOARD URL - Campaign
export const SEC_DATA_CAMPAIGN_STATS_URL = '/sec/data-board/campaign/stats';
export const SEC_DATA_CAMPAIGN_METRICS_URL = '/sec/data-board/campaign/metrics-stats';
export const SEC_DATA_CAMPAIGN_INVESTOR_STATS_URL = '/sec/data-board/campaign/investor-stats';
export const SEC_DATA_CAMPAIGN_GOAL_MIN_PERCENTAGE_URL = '/sec/data-board/campaign/goal-min-percentage-by-day';
export const SEC_DATA_CAMPAIGN_ACHIEVEMENT_PERCENTAGE_URL = '/sec/data-board/campaign/achievement-percentage';
export const SEC_DATA_CAMPAIGN_METRICS_STATS_URL = '/sec/data-board/campaign/:campaignId/metrics-stats';
export const SEC_DATA_CAMPAIGN_USER_AGE_STATS_URL = '/sec/data-board/campaign/:campaignId/user/age-stats';
export const SEC_DATA_CAMPAIGN_USER_JOB_STATS_URL = '/sec/data-board/campaign/:campaignId/user/job-stats';
export const SEC_DATA_CAMPAIGN_USER_UTM_STATS_URL = '/sec/data-board/campaign/:campaignId/user/utm-stats';
export const SEC_DATA_CAMPAIGN_ORDER_CHART_URL = '/sec/data-board/campaign/:campaignId/order/charts/:period';
export const SEC_DATA_CAMPAIGN_ORDER_CUMULATIVE_CHART_URL = '/sec/data-board/campaign/:campaignId/cumulative-order/charts/:period';
export const SEC_DATA_CAMPAIGN_ORDER_FORECAST_URL = '/sec/data-board/campaign/:campaignId/order/forecast';
export const SEC_DATA_CAMPAIGN_ORDER_UTM_STATS_URL = '/sec/data-board/campaign/:campaignId/investment-order/utm-stats';
export const SEC_DATA_CAMPAIGN_INVESTOR_GENRE_URL = '/sec/data-board/campaign/:campaignId/investors-count/genre';
export const SEC_DATA_CAMPAIGN_INVESTOR_QUOTA_URL = '/sec/data-board/campaign/:campaignId/investors-count/quota-label';
export const SEC_DATA_CAMPAIGN_UTM_COUNT_USER_URL = '/sec/data-board/campaign/:campaignId/user/count/utm-assignment';
export const SEC_DATA_CAMPAIGN_UTM_NEW_USER_URL = '/sec/data-board/campaign/:campaignId/user/new/utm-assignment';
export const SEC_DATA_CAMPAIGN_UTM_ASSIGNMENT_URL = '/sec/data-board/campaign/:campaignId/utm-assignment';
export const SEC_DATA_CAMPAIGN_INVESTOR_EDUCATION_URL = '/sec/data-board/campaign/:campaignId/investors-count/mifid/education';
export const SEC_DATA_CAMPAIGN_INVESTOR_KNOWLEDGE_URL = '/sec/data-board/campaign/:campaignId/investors-count/mifid/knowledge';
export const SEC_DATA_CAMPAIGN_INVESTOR_RETURNING_URL = '/sec/data-board/campaign/:campaignId/investors-count/returning';
export const SEC_DATA_CAMPAIGN_FUNNEL_URL = '/sec/data-board/campaign/:campaignId/funnel';
export const SEC_DATA_CAMPAIGN_FUNNEL_BENCHMARK_URL = '/sec/data-board/campaign/:campaignId/funnel/benchmark';
export const SEC_DATA_CAMPAIGN_INVESTORS_BY_CITY_URL = '/sec/data-board/campaign/:campaignId/investors-count/address/city';
export const SEC_DATA_CAMPAIGN_EARLY_BIRD_STATS_URL = '/sec/data-board/campaign/early-bird-stats';

// DASHBOARD URL - Payment
export const SEC_DATA_PAYMENT_CHART_URL = '/sec/data-board/payment/charts/:period';
export const SEC_DATA_PAYMENT_METRICS_URL = '/sec/data-board/payment/metrics/:period';

// DASHBOARD URL - Order
export const SEC_DATA_ORDER_METRICS_URL = '/sec/data-board/order/metrics/:period';
export const SEC_DATA_ORDER_CHART_URL = '/sec/data-board/order/charts/:period';
export const SEC_DATA_ORDER_FUNNEL_URL = '/sec/data-board/order/funnel/:period';
export const SEC_DATA_ORDER_FORECAST_URL = '/sec/data-board/order/forecast';

// DASHBOARD URL - Marketing Email
export const SEC_DATA_MKT_EMAIL_CHART_URL = '/sec/data-board/email-mkt/charts/:period'
export const SEC_DATA_MKT_EMAIL_METRICS_URL = '/sec/data-board/email-mkt/metrics'

// DASHBOARD URL - Notification
export const SEC_DATA_NOTIFICATION_CHART_URL = '/sec/data-board/notification/chart/:period';
export const SEC_DATA_NOTIFICATION_SENT_URL = '/sec/data-board/notification/sent/:period';

// DASHBOARD URL - Competitor
export const SEC_DATA_COMPETITOR_CHART_URL = '/sec/data-board/competitor-chart/:period';

// DASHBOARD URL - Lead Metrics
export const SEC_DATA_LEAD_METRICS_URL = '/sec/data-board/campaign-application/lead/metrics';

// DASHBOARD URL - Log Error
export const SEC_LOG_ERROR_FE_URL = '/sec/log/fe-error';
export const SEC_LOG_ERROR_BE_URL = '/sec/log/be-error';

//-------------------------------------------------------------------------------------
// ROUTE
//-------------------------------------------------------------------------------------

export const HOME_PAGE_ROUTE = '/';

// PROJECT ROUTE
export const CAMPAIGNS_ROUTE = '/projects';
export const CAMPAIGN_ROUTE = '/project/:systemTitle';
export const EQUITY_CALCULATION_ROUTE = '/project/:systemTitle/amount';
export const CAMPAIGN_INVESTMENT_ORDER_ROUTE = '/project/:systemTitle/invest';
export const PISP_PAGE_ROUTE = '/project/:systemTitle/pay/:investmentOrderId';
export const PISP_REDIRECT_PAGE_ROUTE = '/project/:systemTitle/pay/:investmentOrderId/finalize';
export const PISP_SUCCESS_PAGE_ROUTE = '/project/:systemTitle/pay/:investmentOrderId/success';
export const PISP_FAIL_PAGE_ROUTE = '/project/:systemTitle/pay/:investmentOrderId/error';

// ME ROUTE
export const SIM_PAGE_ROUTE = '/me/sim-users';
export const MONITORING_PAGE_ROUTE = '/me/monitoring-report';
export const ME_PORTFOLIO_ROUTE = '/me/portfolio';
export const ME_PORTFOLIO_DETAIL_ROUTE = '/me/portfolio/:fiscalCode/:campaignId';
export const ME_PROFILE_ROUTE = '/me/profile';
export const ME_CHANGE_PASSWORD_ROUTE = '/me/change-password';
export const ME_UPLOAD_DOCUMENT_ROUTE = '/me/upload/id-card';
export const ME_WATCHLIST_ROUTE = '/me/followed-campaigns';
export const ME_EARLY_STAGE_ADVISORY_ROUTE = '/me/early-stage-advisory';
export const ME_CAMPAIGNS_ROUTE = '/me/campaigns';
export const ME_COMPANY_MONITORING_ROUTE = '/me/company-monitoring';
export const ME_COMPANY_MONITORING_FORM_ROUTE = '/me/company-monitoring/:companyMonitoringId';
// export const CAMPAIGN_WIZARD_CREATE_ROUTE = '/me/campaign/create'; DEPRECATED
export const ME_CAMPAIGN_WIZARD_ROUTE = '/me/campaign/:campaignId';
export const ME_CAMPAIGN_PREVIEW_ROUTE = '/me/campaign/:systemTitle/:previewStatus/preview';
export const ME_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE = '/me/campaign/:campaignId/equity-calculator-preview';
export const ME_CAMPAIGN_WIZARD_SUCCESS_ROUTE = '/me/campaign/:campaignId/success';
export const ME_CAMPAIGN_SHAREHOLDERS_ROUTE = '/me/campaign/:campaignId/shareholders';
export const ME_CAMPAIGN_TAX_BONUS_ROUTE = '/me/campaign/:campaignId/tax-bonus';
export const ME_CAMPAIGN_ANCHORS_ROUTE = '/me/campaign/:campaignId/anchors';
export const ME_CAMPAIGN_STATS_ROUTE = '/me/campaign/:campaignId/stats';
export const ME_CAMPAIGN_DOCUMENTS_ROUTE = '/me/campaign/:campaignId/documents';
export const ME_CAMPAIGN_COMMUNICATIONS_ROUTE = '/me/campaign/:campaignId/communications';
export const ME_SPID_VALIDATOR_ROUTE = '/me/spid-validator';

// WEB ROUTE
export const APPLY_PAGE_ROUTE = '/_/raise/info';
export const APPLY_NEW_PROJECT_EQUITY_ROUTE = '/_/raise/apply';
export const APPLY_PROJECT_EQUITY_ROUTE = '/_/raise/apply/:applicationId';
export const APPLY_NEW_PROJECT_EQUITY_ROUTE_STEP_2 = '/_/raise/apply/:applicationId/step-2';
export const VIEW_APPLIED_PROJECT_ROUTE = '/_/raise/view/:applicationId';
export const APPLY_SUCCESS_PROJECT_ROUTE = '/_/applied/:applicationId';
export const APPLY_STEP_2_SUCCESS_PROJECT_ROUTE = '/_/applied/:applicationId/step-2';
export const INDEX_KNOWLEDGE_OFFEROR_SME_ROUTE = '/_/knowledge/how-to-create-campaign';
export const KNOWLEDGE_OFFEROR_SME_ROUTE = '/_/knowledge/how-to-create-campaign/:systemTitle';
export const INDEX_KNOWLEDGE_OFFEROR_RE_ROUTE = '/_/knowledge/how-to-create-campaign-real-estate';
export const KNOWLEDGE_OFFEROR_RE_ROUTE = '/_/knowledge/how-to-create-campaign-real-estate/:systemTitle';
export const INDEX_KNOWLEDGE_INVESTOR_ROUTE = '/_/knowledge/how-to-invest';
export const KNOWLEDGE_INVESTOR_ROUTE = '/_/knowledge/how-to-invest/:systemTitle';
export const AUCAP_TOOL_ROUTE = '/_/tools/aucap';
export const ANNEX_3_TOOL_ROUTE = '/_/tools/annex3';
export const SUCCESS_STORIES_ROUTE = '/_/success-stories';
export const WHO_ARE_ROUTE = '/_/chi-siamo';
export const HOW_TO_INVEST_ROUTE = '/_/come-iniziare';
export const CARRERS_PAGE_ROUTE = '/_/lavora-con-noi';
export const PRESS_ROUTE = '/_/press';
export const POWER_USER_ROUTE = '/_/programma-power-user';
export const TERMS_AND_CONDITION_POWER_USER_ROUTE = '/_/termini-condizioni-programma-power-user';
export const GUIDE_LINES_ROUTE = '/_/linee-guida-commenti';
export const COMPANY_INFO_ROUTE = '/_/informazioni-societarie';
export const MINIBOND_ROUTE = '/_/minibond';
export const TERMS_ROUTE = '/_/termini';
export const COOKIES_ROUTE = '/_/cookies';
export const PRIVACY_ROUTE = '/_/privacy';
export const PRIVACY_SELLER_ROUTE = '/_/privacy-offerente';
export const APPROPRIATENESS_CHECK_ROUTE = '/_/verifica-di-appropriatezza';
export const SIM_HELP_PAGE_ROUTE = '/_/regime-alternativo-di-intestazione-delle-quote';
export const SIM_TERMS_PAGE_ROUTE = '/_/termini-e-condizioni-regime-alternativo-intestazione-quote';
export const TRANSFER_TYPE_TERMS_ROUTE = '/_/regime-dematerializzazione';
export const ADDITIONAL_RISK_WARNING_ROUTE = '/_/avvertenza-rischi';
export const EQUITY_CROWDFUNDING_ROUTE = '/_/investire-con-equity-crowdfunding';
export const DATA_PROCESSING_POLICY_ROUTE = '/_/politica-sul-trattamento-dei-dati';
export const PRIVACY_POLICY_TEAM_MEMBER_ROUTE = '/_/privacy-policy-team-member';
export const BENEFITS_ROUTE = '/_/agevolazioni';
export const CROWDFUNDING_SERVICE_ROUTE = '/_/servizi-crowdfunding';
export const RELATED_INVESTMENTS_COSTS_ROUTE = '/_/costi-connessi-investimento';
export const TRUSTEE_SERVICE_ROUTE = '/_/servizio-intestazione-fiduciaria';
export const CONTACTS_ROUTE = '/_/contatti';
export const CLAIMS_POLICY_ROUTE = '/_/policy-trattamento-reclami';
export const CONFLICT_INTEREST_POLICY_ROUTE = '/_/policy-conflitti-di-interessi';
export const STATISTICS_ROUTE = '/_/statistics';
export const PAGE_ROUTE = '/_/:systemTitle';

// FAQ ROUTE
export const FAQ_ROUTE = '/faq';

// NEWS ROUTE
export const NEWS_PAGE_ROUTE = '/news';
export const BLOG_ARTICLE_ROUTE = '/news/:blogArticleRoute';

// ERRROR ROUTE
export const ERROR_ROUTE = '/error';

// NOT FOUND ROUTE
export const NOT_FOUND_PAGE_ROUTE = '/not-found';

// ACCESS DENIED ROUTE
export const ACCESS_DENIED_PAGE_ROUTE = '/access-denied';

// AUTH ROUTE
export const LOGIN_ROUTE = '/login';
export const TOKEN_LOGIN_ROUTE = '/login/token/:token';
export const SPID_LOGIN_ROUTE = '/login/spid';
export const SPID_LOGIN_SUCCESS_ROUTE = '/login/spid/success';
export const REGISTER_ROUTE = '/signup';
export const FORGOT_ROUTE = '/forgot';
export const ACTIVATION_MAIL_ROUTE = '/activate/:token';
export const RESET_PASSWORD_ROUTE = '/reset/:token';
export const TEAM_MEMBER_REGISTER_ROUTE = '/team-member/signup';
export const LOGIN_DEFAULT_REDIRECT_URL_REGEXP = /^\/(it|en)?\/?$/i;

// SOCIAL ROUTE
export const SOCIAL_AUTHORIZATION_LOGIN_ROUTE = '/social/authorization/login';
export const SOCIAL_AUTHORIZATION_REGISTER_ROUTE = '/social/authorization/register';
export const GOOGLE_AUTHORIZATION_LOGIN_ROUTE = '/social/google/login';
export const GOOGLE_AUTHORIZATION_REGISTER_ROUTE = '/social/google/register';
export const FACEBOOK_AUTHORIZATION_LOGIN_ROUTE = '/social/facebook/login';
export const FACEBOOK_AUTHORIZATION_REGISTER_ROUTE = '/social/facebook/register';
export const LINKEDIN_AUTHORIZATION_LOGIN_ROUTE = '/social/linkedin/login';
export const LINKEDIN_AUTHORIZATION_REGISTER_ROUTE = '/social/linkedin/register';
export const LINKEDIN_AUTHORIZATION_ACCESS_ROUTE = '/social/linkedin/access';

// CONSOLE ROUTE
export const CONSOLE_PREFIX = '/console';
export const CONSOLE_HOME_PAGE_ROUTE = '/';
export const CONSOLE_LIST_CAMPAIGNS_ROUTE = '/campaigns';
export const CONSOLE_CAMPAIGN_ROUTE = '/campaigns/:campaignId';
export const CONSOLE_CREATE_CAMPAIGN_ROUTE = '/campaigns/new';
export const CONSOLE_STATISTIC_CAMPAIGN_ROUTE = '/campaigns/:campaignId/stats';
export const CONSOLE_CAMPAIGN_ORDERS_ROUTE = '/campaigns/:campaignId/orders';
export const CONSOLE_CAMPAIGN_DOCUMENTS_VALIDATION_ROUTE = '/campaigns/:campaignId/documents-validation';
export const CONSOLE_CAMPAIGN_INCOMPLETE_ORDERS_ROUTE = '/campaigns/:campaignId/incomplete-orders';
export const CONSOLE_CAMPAIGN_SIM_ROUTE = '/campaigns/:campaignId/sim';
export const CONSOLE_CAMPAIGN_BILLING_ROUTE = '/campaigns/:campaignId/billing';
export const CONSOLE_INVESTMENT_BANK_ORDER_ROUTE = '/campaigns/:campaignId/orders-for-bank';
export const CONSOLE_OPERATIONS_PAGE_ROUTE = '/campaigns/:campaignId/operations';
export const CONSOLE_CAMPAIGN_INVESTORS_LIST_ROUTE = '/campaigns/:campaignId/investors-list';
export const CONSOLE_CAMPAIGN_INVESTORS_LIST_DRAFT_ROUTE = '/campaigns/:campaignId/investors-list-draft';
export const CONSOLE_CAMPAIGN_EARLY_BIRD_ROUTE = '/campaigns/:campaignId/early-bird';
export const CONSOLE_CAMPAIGN_FOLLOWERS_ROUTE = '/campaigns/:campaignId/followers';
export const CONSOLE_CAMPAIGN_ANNEX_3_ROUTE = '/campaigns/:campaignId/annex3';
export const CONSOLE_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE = '/campaigns/:campaignId/equity-calculator-preview';
export const CONSOLE_LATE_PAYMENTS_ROUTE = '/late-payments';
export const CONSOLE_LIST_INCOMPLETE_ORDERS_ROUTE = '/incomplete-orders';
export const CONSOLE_LIST_ORDERS_ROUTE = '/orders';
export const CONSOLE_ORDER_ROUTE = '/orders/:orderId';
export const CONSOLE_LIST_COMPANIES_ROUTE = '/companies';
export const CONSOLE_COMPANY_ROUTE = '/companies/:companyId';
export const CONSOLE_CREATE_COMPANY_ROUTE = '/companies/new';
export const CONSOLE_BILLING_ROUTE = '/billing';
export const CONSOLE_LIST_USERS_ROUTE = '/users';
export const CONSOLE_USER_ROUTE = '/users/:userId';
export const CONSOLE_CREATE_USER_ROUTE = '/users/new';
export const CONSOLE_LIST_PARTNERS_ROUTE = '/partners';
export const CONSOLE_PARTNER_ROUTE = '/partners/:partnerId';
export const CONSOLE_CREATE_PARTNER_ROUTE = '/partners/new';
export const CONSOLE_LIST_TESTIMONIALS_ROUTE = '/testimonials';
export const CONSOLE_TESTIMONIALS_ROUTE = '/testimonials/:testimonialId';
export const CONSOLE_CREATE_TESTIMONIALS_ROUTE = '/testimonials/new';
export const CONSOLE_LIST_ARTICLES_ROUTE = '/articles';
export const CONSOLE_ARTICLE_ROUTE = '/articles/:articleId';
export const CONSOLE_CREATE_ARTICLE_ROUTE = '/articles/new';
export const CONSOLE_LIST_MESSAGES_ROUTE = '/messages';
export const CONSOLE_MESSAGE_ROUTE = '/messages/:messageId';
export const CONSOLE_CREATE_MESSAGE_ROUTE = '/messages/new';
export const CONSOLE_LIST_BANNERS_ROUTE = '/banners';
export const CONSOLE_BANNER_ROUTE = '/banners/:bannerId';
export const CONSOLE_CREATE_BANNER_ROUTE = '/banners/new';
export const CONSOLE_LIST_PAGES_ROUTE = '/pages';
export const CONSOLE_PAGE_ROUTE = '/pages/:pageId';
export const CONSOLE_CREATE_PAGE_ROUTE = '/pages/new';
export const CONSOLE_LIST_PRESS_ROUTE = '/press';
export const CONSOLE_PRESS_ROUTE = '/press/:pressId';
export const CONSOLE_CREATE_PRESS_ROUTE = '/press/new';
export const CONSOLE_LIST_SETTINGS_ROUTE = '/settings';
export const CONSOLE_SETTING_ROUTE = '/settings/:key';
export const CONSOLE_CREATE_SETTING_ROUTE = '/settings/new';
export const CONSOLE_LIST_FAQ_ROUTE = '/faq';
export const CONSOLE_FAQ_ROUTE = '/faq/:faqId';
export const CONSOLE_CREATE_FAQ_ROUTE = '/faq/new';
export const CONSOLE_LIST_INDUSTRIES_ROUTE = '/industries';
export const CONSOLE_INDUSTRY_ROUTE = '/industries/:industryId';
export const CONSOLE_CREATE_INDUSTRY_ROUTE = '/industries/new';
export const CONSOLE_STATISTICS_ROUTE = '/statistics';
export const CONSOLE_CUSTOM_EMAIL_ROUTE = '/custom-notification';
export const CONSOLE_CUSTOM_MKT_EMAIL_ROUTE = '/custom-marketing-email';
export const CONSOLE_EMAIL_SERVICE_STATUS_ROUTE = '/email-service-status';
export const CONSOLE_GUIDES_PAGE_ROUTE = '/guides';
export const CONSOLE_MEDIA_PAGE_ROUTE = '/media';
export const CONSOLE_URL_CHECKER_ROUTE = '/url-checker';
export const CONSOLE_LIST_COMPETITORS_ROUTE = '/competitors';
export const CONSOLE_CREATE_COMPETITORS_CAMPAIGN_ROUTE = '/competitors/new';
export const CONSOLE_COMPETITORS_CAMPAIGN_ROUTE = '/competitors/:competitorId';
export const CONSOLE_COMPANY_MONITORING_ROUTE = '/company-monitoring';
export const CONSOLE_CHAMBER_DOC_PAGE_ROUTE = '/chamber-doc';
export const CONSOLE_LIST_CAMPAIGN_APPLICATION_ROUTE = '/campaign-application';
export const CONSOLE_CAMPAIGN_APPLICATION_HUBSPOT_DEAL_ROUTE = '/hubspot/deal/:dealId';
export const CONSOLE_CAMPAIGN_APPLICATION_ROUTE = '/campaign-application/:applicationId';
export const STYLE_GUIDE_ROUTE = 'https://docs.google.com/document/d/18vjp3dE9DrzwDnvd8bOSVf45BdCuC6BQPViVpXKkodA';

// LANDING ROUTE
export const LANDING_ADS_ROUTE = '/_/landing';
export const LANDING_B2C_ROUTE = '/_/landing/:systemTitle';

// OLD URL MAMACROWD FOR REDIRECT
export const OLD_HOME_PAGE_ROUTE = '/:systemTitle';
export const OLD_CAMPAIGNS_PAGE_ROUTE = '/project';
export const OLD_CAMPAIGN_PAGE_ROUTE = '/projects/:systemTitle';
// export const OLD_CAMPAIGN_PAGE_REFOLDID_1_ROUTE = '/project/:refOldId/:systemTitle';
// export const OLD_CAMPAIGN_PAGE_REFOLDID_2_ROUTE = '/projects/:refOldId/:systemTitle';
// export const OLD_NEWS_PAGE_ROUTE = '/section/blog';
// export const OLD_BLOG_ARTICLE_ROUTE = '/article/:blogArticleRoute';
// export const OLD_2ND_PAGES_ROUTE = '/section/:systemTitle';

//-------------------------------------------------------------------------------------
// COSTANTS
//-------------------------------------------------------------------------------------

export const CONSOLE_SIDEBAR_WIDTH = 200;
export const MIFID_VERSION = '1.2.20200611';
export const MIFID_FUND_VERSION = '1.0.20210104';
export const MIFID_MINIBOND_VERSION = '1.0.20210105';
export const INVESTOR_KNOWLEDGE_VERSION = 'v2.0.20230523';
export const HOME_PAGE_LIMIT = 8;
export const CAMPAIGNS_PAGE_LIMIT = 9;
export const MIN_CARD_WIDTH = 300;
export const PAGE_CONTAINER_PADDING = '24px 40px 36px';
export const PAGE_CONTAINER_MOBILE_PADDING = '24px 20px 36px';
export const COOKIE_BANNER_PADDING = '0 4%';
export const COOKIE_BANNER_PADDING_MOBILE = '0 3%';
export const PADDING_TITLE = '20px 0';
export const UPLOAD_MAX_FILE_SIZE = 20 * 1024 * 1000; // 20M
export const UPLOAD_VIDEO_MAX_FILE_SIZE = 500 * 1024 * 1024; // 50M
export const UPDATES_DAYS_INTERVAL = 14;
export const MAX_WIDTH_PAGE = 1440;
export const HEADER_HEIGHT = 91;
export const HEADER_MOBILE_HEIGHT = 65;
export const CONSOLE_HEADER_HEIGHT = 65;
export const LAST_TOKEN_REFRESH_MINUTES = 1;
export const TOKEN_EXPIRED_SECONDS = 10;
export const USER_UTM = 'userUtm';
export const COOKIE_SETTINGS = 'cookieSetting';
export const EQUITY_FUND_ON_CAMPAIGN_WIZARD_DISABLED = true;
export const SHOW_ACCOUNT_DROPDOWN_MIFID_TYPE = false;
export const SHOW_KNOWLEDGE_CAMPAIGN_OWNER_LINK = true;
export const MAX_DESCRIPTION_SECTION_LIMIT_CHARACTERS = 1500;
export const LOG_AWS_TRACK_EXCEPTION_ENABLED = false;
export const CONSOLE_SWAGGER_LINK = '/sec/api-docs';
export const CROWD_GOAL_MAX_LIMIT = 8000000;
export const CAMPAIGN_CARD_RADIUS = 16;
export const CAMPAIGN_SIDEBAR_MENU_WIDTH = 200;
export const HEADER_SLIDE_TRANSITION = '195ms all';
export const USER_PRIVATE_CAMPAIGN_PROPERTY = 'hasPrivateCampaignInvitation';
export const USER_RESTRICTED_CAMPAIGN_PROPERTY = 'hasRestrictedCampaignInvitation';

// FOOTER DATA
export const MAIL_TO_SUPPORT = 'support@mamacrowd.com';
export const PEC = 'mamacrowd@legalmail.it';
export const SHARE_CAPITAL = '95.417,54'; // '87.248,12'; // '73.267,04';
export const VAT_NUMBER = 'IT07464370969';

// #MMC2: checklink
export const INVESTOR_EDUCATION_LINK = 'http://www.consob.it/web/investor-education/crowdfunding';
export const EXTERNAL_LINK_CONSOB = 'http://www.consob.it/web/area-pubblica/registro-crowdfunding-sezione-ordinaria';
export const EXTERNAL_LINK_DISPUTES = 'https://www.acf.consob.it/it_IT/web/guest/home';
export const DOWNLOAD_KIT_PRESS_URL = 'https://asset.mamacrowd.com/Media/mamacrowd_presskit_20220928.zip';
export const ANCHOR_INVESTORS_TEMPLATE = 'https://mmc-asset.s3.eu-central-1.amazonaws.com/console/TemplateAnchorInvestors.tsv';
export const BANK_IMPORT_TEMPLATE = 'https://mmc-asset.s3.eu-central-1.amazonaws.com/console/TemplateBankImport.tsv';
export const SIM_ACCOUNT_CSV_TEMPLATE = 'https://asset.mamacrowd.com/console/import_sim_template.csv';

// ANCHORS ADDITION DATE LIMIT
export const ANCHORS_ADDITION_DATE_LIMIT = '2021-04-01T05:00:00.000Z';

// INTERCOM
export const INTERCOM_APP_ID = 'dltddpzf';

// GOOGLE SCRIPT ID
export const GOOGLE_PLACES_SCRIPT_ID = 'google-places-library';

// SOCIAL LINK
export const FACEBOOK_SOCIAL_URL = 'https://www.facebook.com/mamacrowdcom/';
export const TWITTER_SOCIAL_URL = 'https://twitter.com/mamacrowdcom';
export const LINKEDIN_SOCIAL_URL = 'https://www.linkedin.com/company/mamacrowd';
export const INSTAGRAM_SOCIAL_URL = 'https://www.instagram.com/mamacrowdcom/';
export const YOUTUBE_SOCIAL_URL = 'https://www.youtube.com/channel/UCSp9JIV6Er98NmVmZh593VQ';

export const LINKEDIN_AUTHORIZATION_ENDPOINT = 'https://www.linkedin.com/oauth/v2/authorization';
export const LINKEDIN_RESPONSE_TYPE = 'code';
export const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress';
export const ENABLE_LINKEDIN_SOCIAL_AUTHENTICATION = true;

export const FACEBOOK_AUTHORIZATION_ENDPOINT = 'https://www.facebook.com/v16.0/dialog/oauth';
export const FACEBOOK_RESPONSE_TYPE = 'code';
export const FACEBOOK_SCOPE = 'public_profile,email';
export const ENABLE_FACEBOOK_SOCIAL_AUTHENTICATION = true;

export const GOOGLE_AUTHORIZATION_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
export const GOOGLE_RESPONSE_TYPE = 'code';
export const GOOGLE_SCOPE = 'openid email profile';
export const ENABLE_GOOGLE_SOCIAL_AUTHENTICATION = true;

// GEOLOCATION
export const GOOGLE_MAPS_GEOLOCATION_ENABLED = false;

// ANIMATIONS
export const DEFAULT_ANIMATION_MARGIN = '0px 0px -50px 0px';
export const TIMING_OPACITY_ANIMATION = '0.5';
export const TIMING_TRANSITION_ANIMATION = '1';
export const TIMING_OPACITY_ANIMATION_MOBILE = '0.3';
export const TIMING_TRANSITION_ANIMATION_MOBILE = '0.6';

// ON BOARDING STORAGE KEY
export const APPLY_COMPANY_KEY = 'onBoardingProfileForm';
export const APPLY_FINANCIAL_KEY = 'onBoardingFinancials';
export const APPLY_PROJECT_KEY = 'onBoardingProjectInfo';
export const APPLY_REFERENT_KEY = 'onBoardingReferent';

// BANCA SELLA ACCOUNT
export const SELLA_IBAN = 'IT89P03268223000A9H00614650';
export const SELLA_SWIFT = 'SELBIT2B';
export const SELLA_HOLDER = 'Banca Sella Spa';

// DIRECTA SIM
export const DIRECTA_SUPPORT_EMAIL = 'crowdfunding@directa.it';
export const DIRECTA_SIM_USER = 'https://directatrading.com/trading/moadecl';
export const DIRECTA_SIM_BUSINESS = 'https://www1.directatrading.com/trading/moadscl';

// LOCAL STORAGE KEYS
export const LS_AUCAP = 'aucapList';
export const LS_ORDER_DETAILS = 'order-detiails';

// TABS
export const CAMPAIGN_OVERVIEW_TAB = 'overview';
export const CAMPAIGN_ROUND_TAB = 'financials';
export const CAMPAIGN_DOCUMENTS_TAB = 'documents';
export const CAMPAIGN_COMMENTS_TAB = 'comments';
export const CAMPAIGN_UPDATES_TAB = 'updates';
export const CAMPAIGN_INVESTORS_TAB = 'investors';

export const CAMPAIGN_OVERVIEW_TEAM_TAB = 'team';

export const CAMPAIGN_DETAILS_ROUND_TAB = 'round';
export const CAMPAIGN_DETAILS_QUOTAS_TAB = 'quotas';
export const CAMPAIGN_DETAILS_SIM_TAB = 'sim';

export const CAMPAIGN_BLOCK_TAB = 'campaign-block-panel';

export const ORDER_INVESTOR_TYPE_STEP = 'investor-type-step-button';
export const ORDER_USER_PROFILE_STEP = 'user-profile-step-button';
export const ORDER_BUSINESS_PROFILE_STEP = 'business-profile-step-button';
export const ORDER_INVESTOR_KNOWLEDGE_STEP = 'investor-profiling-step-button';
export const ORDER_INVESTMENT_STEP = 'investment-step-button';

export const PORTFOLIO_ACTIVE_INVESTMENTS_TAB = 'active-investments';
export const PORTFOLIO_INVESTMENTS_HISTORY_TAB = 'investments-history';
export const PORTFOLIO_COMPANY_SHARES_TAB = 'company-shares';
export const PORTFOLIO_TAXATION_TAB = 'taxation';
export const PORTFOLIO_DETAIL_ORDERS_TAB = 'orders';
export const PORTFOLIO_DETAIL_SIM_TAB = 'sim';
export const PORTFOLIO_DETAIL_COMMUNICATIONS_TAB = 'communications';
export const PORTFOLIO_DETAIL_PITCH_TAB = 'pitch';

export const PROFILE_ACCOUNT_TAB = 'account';
export const PROFILE_POWER_USER_TAB = 'power-user';
export const PROFILE_USER_PROFILE_TAB = 'user-profile';
export const PROFILE_MIFID_TAB = 'mifid';
export const PROFILE_INVESTOR_PROFILING_TAB = 'profiling';
export const PROFILE_BUSINESS_PROFILE_TAB = 'business-profile';
export const PROFILE_NOTIFICATION_TAB = 'notifications';
export const PROFILE_PRIVACY_TAB = 'privacy';
export const PROFILE_MESSAGES_TAB = 'messages'; // Used only in console

export const FAQ_INVESTORS_TAB = 'investitori';
export const FAQ_SIM_TAB = 'rubricazione';
export const FAQ_GENERAL_TAB = 'generali';
export const FAQ_TARGET_SPV_TAB = 'target_spv';
export const FAQ_TRUST_COMPANY_TAB = 'fiduciaria';
export const FAQ_OFFERORS_TAB = 'offerenti';

export const CAMPAIGN_APPLICATIONS_APPLICATION_LIST_TAB = 'application';
export const CAMPAIGN_APPLICATIONS_LEAD_LIST_TAB = 'application-lead';

export const CUSTOM_MKT_NEW_EMAIL_TAB = 'new-email';
export const CUSTOM_MKT_STATS_TAB = 'statistics';

export const CONSOLE_STATS_ECONOMICS_TAB = 'economics';
export const CONSOLE_STATS_USERS_TAB = 'users';
export const CONSOLE_STATS_CAMPAIGNS_TAB = 'campaigns';
export const CONSOLE_STATS_FUNNEL_TAB = 'funnel';
export const CONSOLE_STATS_CRM_TAB = 'crm-emails';
export const CONSOLE_STATS_FORECAST_TAB = 'forecast';
export const CONSOLE_STATS_PLATFORM_TAB = 'platform';

export const CONSOLE_CAMPAIGN_MAMA_TAB = 'campaign-mama';
export const CONSOLE_CAMPAIGN_OFF_TAB = 'campaign-off';
export const CONSOLE_CAMPAIGN_FINANCIALS_DATA_TAB = 'campaign-financials';
export const CONSOLE_CAMPAIGN_BILLING_TAB = 'billing';
export const CONSOLE_CAMPAIGN_WIDGET_TAB = 'widget';
export const CONSOLE_CAMPAIGN_DESCRIPTION_SECTION_TAB = 'descriptionSections';
export const CONSOLE_CAMPAIGN_TEAM_MEMBER_TAB = 'teamMembers';
export const CONSOLE_CAMPAIGN_QUOTAS_TAB = 'quotas';
export const CONSOLE_CAMPAIGN_REWARDS_TAB = 'rewards';
export const CONSOLE_CAMPAIGN_DOCUMENTS_TAB = 'documents';
export const CONSOLE_CAMPAIGN_COMMUNICATIONS_TAB = 'communications';
export const CONSOLE_CAMPAIGN_UPDATES_TAB = 'updates';
export const CONSOLE_CAMPAIGN_ANCHORS_TAB = 'anchorInvestors';

export const CONSOLE_SHAREHOLDERS_LIST_INVESTORS_TAB = 'investors';
export const CONSOLE_SHAREHOLDERS_LIST_SIM_TAB = 'sim';
export const CONSOLE_SHAREHOLDERS_LIST_TRUST_COMPANY_TAB = 'trust-company';
export const CONSOLE_SHAREHOLDERS_LIST_REWARDS_TAB = 'rewards';

// FUND NEWS ROUTE
export const FUND_BANNER_TO_NEWS_URL = '/news/mama-fund-and-crowd';

export const CARD_NEWS_IMAGE_DEFAULT =
  'https://d2rf4y8woysqse.cloudfront.net/Media/20211202161219_eea22e79_news-default-img.jpg';

export const IMAGE_COMPRESSION_QUALITY = 0.9;

// BANNED WORDS
export const BANNED_WORDS = [ 'l', 'il', 'lo', 'la', 'i', 'gli', 'le', 'un', 'uno', 'una', 'di', 'a', 'da', 'in', 'con', 'su', 'per', 'tra', 'fra', 'del', 'dell', 'dello', 'della', 'dei', 'degli', 'delle', 'al', 'all', 'allo', 'alla', 'ai', 'agli', 'alle', 'dal', 'dall', 'dallo', 'dalla', 'dai', 'dagli', 'dalle', 'nel', 'nell', 'nello', 'nella', 'nei', 'negli', 'nelle', 'col', 'coi', 'sul', 'sull', 'sullo', 'sulla', 'sui', 'sugli', 'sulle', 'e', 'ed', 'ad', 'o', 'ma', 'pure', 'mentre', 'come', 'che', 'cos'];

// TRUSTPILOT TEMPLATE ID
export const TRUSTPILOT_MICRO_STAR_TEMPLATE_ID = '5419b732fbfb950b10de65e5'
