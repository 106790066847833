import makeStyles from '@mui/styles/makeStyles';
import { CONSOLE_SIDEBAR_WIDTH } from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  contentContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${CONSOLE_SIDEBAR_WIDTH}px)`,
      '@media print': {
        width: '100%',
      },
    },
    padding: 15,
    '& a:not([class^="MuiButton"])': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
