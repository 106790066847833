import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
}));
