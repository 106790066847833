import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  footerLink: {
    display: 'block',
    textDecoration: 'none',
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  linkText: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
