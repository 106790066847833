/**
 *
 * UserButton
 *
 */
import React from 'react';

import { Typography, Chip, Button } from '@mamacrowd/ui-kit';
import { Theme, useMediaQuery } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';

import messages from 'containers/Header/messages';
import FormattedMessage from 'components/FormattedMessage';

import UserLogged from 'images/UserLogged/user-logged-true.svg';

import { useStyle } from './UserButton.style';

export interface UserButtonProps {
  firstName?: string;
  isLogged?: boolean;
  isPowerUser?: boolean;
  isMenuOpen?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function UserButton({
  firstName,
  isLogged,
  isPowerUser,
  isMenuOpen,
  onClick,
}: UserButtonProps) {
  const classes = useStyle({ isPowerUser, isMenuOpen, isLogged });
  const isOverMD = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  if (isOverMD) {
    return (
      <Button
        variant="text"
        onClick={onClick}
        startIcon={<img src={UserLogged} alt="logged-user-icon" />}
        endIcon={<KeyboardArrowDownRounded className={classes.expandIcon} />}
        className={classes.userButton}
      >
        {firstName ? (
          <Typography variant="inherit" className={classes.userButtonLabel}>
            {firstName}
            {isPowerUser && (
              <Chip
                label={
                  <FormattedMessage messages={messages} messageId="powerUser" />
                }
                size="small"
                color="info"
                variant="outlined"
                className={classes.powerUserChip}
              />
            )}
          </Typography>
        ) : (
          ''
        )}
      </Button>
    );
  }

  return (
    <Button
      flat
      size="small"
      variant="secondary"
      color="secondary"
      onClick={onClick}
      className={classes.userButtonMobile}
      style={isMenuOpen ? { border: 'none' } : {}}
      startIcon={isLogged && <img src={UserLogged} alt="logged-user-icon" />}
      endIcon={
        isMenuOpen ? (
          <Close
            color="action"
            data-testid="user-close-icon"
            className={classes.menuIcon}
          />
        ) : (
          <Menu
            color="action"
            data-testid="user-menu-icon"
            className={classes.menuIcon}
          />
        )
      }
    />
  );
}

export default UserButton;
