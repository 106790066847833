import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  isPowerUser?: boolean;
  isMenuOpen?: boolean;
  isLogged?: boolean;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  userButton: ({ isPowerUser }) => ({
    paddingLeft: isPowerUser ? 17 : 8,
  }),
  userButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  powerUserChip: {
    backgroundColor: theme.palette.common.white,
    height: 'auto',
    marginTop: -4,
    '& .MuiChip-label': {
      padding: '0 4px',
      fontSize: 9,
    },
  },
  menuIcon: {
    fontSize: 24,
    color: '#000000',
  },
  expandIcon: ({ isMenuOpen }) => ({
    transition: 'transform 300ms',
    transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  userButtonMobile: ({ isLogged }) => ({
    height: 40,
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    ...(!isLogged && {
      width: 40,
      minWidth: 'auto',
    }),
  }),
}));
